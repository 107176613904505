.work-zone{
    padding-left: 384px;
    scroll-behavior: smooth;
    min-height: 100vh;
    height: 100%;
    
    // @media (max-width: 1600px) {
    //     padding-left: 275px;
    // } 

    &.sidebar-none{
        padding-left: 0;
    }
    &__wrap{
        padding: 24px 20px 0px;
        width: 100%;
        height: 100%;
    }

    &__title{
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #1A1D1F;
        margin:38px 0 24px;
        @media (max-width: 1600px){
            font-size: 24px;
            line-height: 24px;
            margin: 10px 0 10px;
        }
    }
}