.owner{
    &__points-sale{
        background-color: #fff;
        border-radius: 8px;
        width: 100%; 
        padding: 28px 24px;
        height: 100%;

        @media (max-width: 550px) {
            padding: 20px 4px;
        }

        &-content{
            height: calc(100% - 55px);
        }

        &-table{
            height: calc(100% - 45px);
            @media (max-width: 700px) {
                min-height: 300px;
                height: 100%;
            }

            &-scroll{
                height: 100%;

                overflow-y: scroll;
                overflow-x: hidden;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }

            &-buttons{
                width: 84px;
                margin: auto;

                &-item{
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                    pointer-events: none;

                    &.back{
                        background: url(./../../../../img/pages-back-button.svg);
                        transition: .3s all;
                        &.active{
                            background: url(./../../../../img/pages-back-button-active.svg);
                            pointer-events:inherit;
                        }
                    }

                    &.forward{
                        background: url(./../../../../img/pages-forward-button.svg);
                        transition: .3s all;
                        &.active{
                            background: url(./../../../../img/pages-forward-button-active.svg);
                            pointer-events:inherit;
                        }
                    }
                }
            }
        }

        &-top{
            margin-bottom: 45px;

            @media (max-width: 700px) {
                margin-bottom: 10px;
            }

            @media (max-width: 600px) {
                padding: 0px 24px;
            }
        }

        &-search{
            max-width: 396px;
            width: 100%;
            height: auto;
            margin-right: 10px;
            margin-top: -8px;
            padding-top: 8px;
            border-radius: 12px 12px 0px 0px;
            position: relative;
            transition: .3s all;
            
            &.active{
                background: #F4F4F4;
                padding: 8px 8px 10px;
            }
    
            &-container{
                width: 100%;            
                border-radius: 12px;
                padding: 0px 15px;
                height: 48px;
                background: #F4F4F4;
                transition: .3s all;
                outline-color: #2a85ff33;
    
                &:focus-within{
                    outline: 4px solid #2a85ff33;
                }
        
                &.active{
                    outline: 2px solid #2A85FF;
                }
               
            }
    
            &-container.active &-close{
                display: block;
            }
            
    
            &-icon{
               width: 22px;
               height: 22px;
               margin-right: 11px;
               background: url(/img/search-normal.svg);
               background-size: cover;
    
               
            }
            &:focus-within &-icon{
                background: url(/img/search-blue.svg);
                background-size: cover;
            }        
    
            &-input, &-input::placeholder{
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #70767C;
            }
    
            &-input{
                width: calc(100% - 33px);
                color: #1A1D1F;
            }
            
            &-close{
                width: 25px;
                height: 25px;
                margin-left: 10px;
                display: block;
                cursor: pointer;
                display: none;
                
                &-active{
                    display: block;
                }
            }
    
            &-list{
                display: none;
                position: absolute;
                top: 65px;
                left: 0;            
                width: 100%;
                border-radius: 0px 0px 8px 8px;
                z-index: 1;
                background-color: #FCFCFC;
                padding: 24px 14px 26px;
                box-shadow: 0px 20px 30px #1a1d1f1f;
    
                &-scrol{
                    max-height: 200px;
                    overflow-y: auto;
                    padding-right: 20px;
    
                    &::-webkit-scrollbar{
                        width: 16px;            
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #E5E5E5;
                        border-radius: 10px;
                        
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #FCFCFC;
                        border-radius: 10px;
                        border: 4px solid #E5E5E5;
                    }
                }
    
                &-title{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #70767C;
                    margin-bottom: 10px;
                }
    
                &-item{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #70767C;
                    transition: .3s all;
                    padding: 12px 8px;
                    cursor: pointer;
    
                    & span{
                        color: #1A1D1F;
                        font-weight: 600;
                    }
    
                    &:hover{
                        background: #F4F4F4;
                        border-radius: 8px;
                    }
                }
    
            }
        }

        &-income-title{
            padding: 12px 16px;
            height: 48px;
            background: #FCFCFC;
            border: 2px solid #F4F4F4;
            box-sizing: border-box;
            border-radius: 12px;
            color: #83BF6E;
            cursor: pointer;

            &.green &-icon{
                background: url(/img/arrow-up.svg);
                background-size: 100%;
            }
            &-icon{
                width: 24px;
                height: 24px;
                margin-left: 8px;
                margin-right: -8px;
            }

            @media (max-width: 1200px) {
                padding: 12px 12px;
                & span{
                    display: none;
                }

                &-icon{
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }

        &-checkbox{
            display: flex;
            align-items: center;
            position: relative;   
            transition: 0.3s;
            height: 20px;                
            cursor: pointer;
            width: 20px;
            margin-right: 34px;

            &::before{
                content: '';
                position: absolute;
                background: #fff;
                width: 20px; 
                height: 20px;
                left: 0px;
                transition: 0.3s;
                border: 1.5px solid #393d3f;
                border-radius: 7px;
                cursor: pointer;
            }

            &.active::before{
                background: url(/img/check.svg) center #2A85FF no-repeat;
                background-size: 70%;
                border-color: #2A85FF;
            }
        }

        &-line{
            height: 72px;
            // border-radius: 8px;
            // margin: auto;
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            width: calc(100% - 24px);;
            // padding: 0px 18px;
            transition: .3s all;
            border-bottom: 1px solid #F4F4F4;
            
            &.title{
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                border-bottom: 2px solid #f4f4f4;
                width: calc(100% - 36px);
                padding: 0px;
                // margin: auto;
                // margin-right: 10px;
                margin-bottom: 8px;
                border-radius: 0px;
                padding-bottom: 15px;
                height: auto;

            }

            &.active{
                background: #F4F4F4;
            }

            @media (max-width: 1200px) {
                font-size: 16px;
            }

            @media (max-width: 700px) {
                height: 50px;                
            }
        }

        &-town{
            width: 22%;
            margin-right: 5px;            
        }

        &-name{
            width: 26%;
            margin-right: 5px;            
        }
        &-leader{
            width: 30%;
            margin-right: 5px;
            display: flex;
            padding-right: 5px;

            &-photo{
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 16px;
                overflow: hidden;

                &-icon{
                    height: 32px;
                    border-radius: 16px;
                }
            }

        }
        &-income{
            width: 20%;
            margin-right: 5px;            
        }

    }         
}


@media (max-width: 1500px) {
    .owner{
        &__points-sale{  
            
            &-town{
                display: none;           
            }
    
            &-name{
                width: 33%;
                margin-right: 5px;            
            }
            &-leader{
                width: 44%;
                margin-right: 5px;
    
                &-photo{
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 16px;
                    display: block;
                    object-fit: cover;
                }
    
            }
            &-income{
                width: 22%;
                margin-right: 5px;            
            }
    
        }         
    }
}

@media (max-width: 1100px) {
    .owner{
        &__points-sale{    
            &-name{
                width: 33%;
                margin-right: 5px;            
            }
            &-leader{
                width: 40%;
                margin-right: 5px;
    
                &-photo{
                    display: none;
                }
    
            }
            &-income{
                width: 26%;
                margin-right: 5px;            
            }
    
        }         
    }
}

@media (max-width: 700px) {
    .owner{
        
        &__points-sale{ 
            
            &-checkbox{
                height: 18px;                
                width: 18px;
                margin-right: 20px;
            }

            &-name{
                width: 53%;            
            }
            
            &-leader{
                display: none;
            }

            &-income{
                width: 37%;
                margin-right: 5px;            
            }
        }         
    }
}