.moderator{
    &__header{
        position: relative;
        width: 100%;
        display: flex;

            

    }
    
    &__buttons{
        // padding-top: 8px;
        margin-left: 40px;
        padding-left: auto;
        // width: 320px;
        &-item{
            position: relative;
        }

        &-notifications, &-history-changes{
            width: 40px;
            height: 40px;
            background: #FCFCFC;
            border-radius: 50%;
            cursor: pointer;

            &.active{
                background: #1A1D1F;
            }
        }

        &-notifications, &-history-changes{
            margin-right: 24px;
            transition: .3s all;
            position: relative;

            &.notification::after{
                content: " ";
                position: absolute;
                top: 8px;
                right: 8.5px;
                width: 8px;
                height: 8px;
                background: #FF6A55;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
            }

            &.active::after, &.notific-none::after{
                content: none;
            }


            &-icon{
                width: 24px;
                height: 24px;                
            }
        }

        &-popup{
            position: absolute;
            top: 56px;
            right: 34px;
            z-index: 2;
            background: #FCFCFC;
            width: 400px;
            max-height: 420px;
            border: 0.5px solid #F4F4F4;
            box-shadow: 0px 10px 24px rgba(26, 29, 31, 0.08);
            border-radius: 12px;
            padding: 20px 12px;
            transition: .3s all;
            transform: translateY(-700px);

            &.active{
                transform: translateY(0px);
            }

            &-title{
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 500;
                padding-left: 8px;
            }

            &-btn{
                width: 100%;
                padding: 11px;
                text-align: center;
                background: #1C7FFF;
                border-radius: 8px;
                cursor: pointer;
                transition: .3s all;
                color: #FCFCFC;
                font-size: 14px;
                font-weight: 500;
            }
        }

        &-history-changes-list, &-notifications-list{
            width: 100%;
            max-height: 285px;
            margin-bottom: 18px;
            padding-right: 10px;

            overflow-y: auto;

            &::-webkit-scrollbar{
                width: 12px;            
            }

            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }

            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 4px solid #E5E5E5;
            }

            &-item{
                width: 100%;
                margin-bottom: 6px;
                border-radius: 8px;
                padding:6px 8px;
                transition: .3s all;
                cursor: pointer;

                &:hover{
                    background: #F4F4F4;
                }

                &-container{
                    width: calc(100% - 50px);
                }

                &-icon{
                    width: 36px;
                    height: 36px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    margin-right: 10px;

                    & img{
                        height: 18px;
                        display: block;
                    }
                }

                &-name{
                    width: 100%;
                    &-title{
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 3px;
                        color: #1A1D1F;

                        &.decrease{
                            padding-right: 10px;
                            white-space: nowrap; /* Запрещаем перенос строк */
                            overflow: hidden; /* Обрезаем все, что не помещается в область */
                            text-overflow: ellipsis; /* Добавляем многоточие */
                        }
                    }

                    &-path{
                        color: #90959D;
                        font-size: 10px;
                        white-space: nowrap; /* Запрещаем перенос строк */
                        overflow: hidden; /* Обрезаем все, что не помещается в область */
                        text-overflow: ellipsis; /* Добавляем многоточие */
                        padding-right: 15px;


                        &-item{
                            margin-right: 2px;
                            font-weight: 500;
                            transition: .3s all;
                            cursor: pointer;

                            &:hover{
                                color: #3a3a3a;
                            }

                            &.active{
                                color: #1A1D1F;
                            }

                            & span{
                                // margin-right: 2px;
                                display: inline-block;
                            }
                        }
                    }
                }

                &-status{
                    margin-left: auto;
                    padding-top: 3px;
                    
                    &-time{
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 12px;
                        color: #555D67;
                        margin-right: 6px;
                    }

                    &-icon{
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;

                        &.green{
                            background: #83BF6E;
                        }
    
                        &.red{
                            background: #FF6A55;
                        }
    
                        &.blue{
                            background: #1C7FFF;
                        }

                        &.grey{
                            background: #e4e4e4;
                        }
                    }


                }
            }
        }

        &-user{
            &-photo{
                height: 48px;
                
                &-container{
                    width: 48px;
                    height: 48px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 24px;
                    margin-right: 17px; 
                }

                width: 18px;
                height: 24px;
                display: block;

                &-background{
                    width: 48px;
                    height: 48px;
                    border-radius: 24px;
                    background-color: #FCFCFC;
                    margin-right: 17px;
                }
            }

            &-name{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                &-verify{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #5D676F;
    
                    &-icon{
                        width: 15px;
                        height: 15px;
                        display: block;
                        margin-left: 4px;
                    }
                }
            }
            &-button{
                margin-left: 10px;
                width: 15px;
                height: 7px;
                display: block;
                cursor: pointer;
            }
            
        }
    }
}