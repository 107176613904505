.franchises-leaders__staf-points{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 75vh;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    
    &-top{
        margin-bottom: 20px;

        &-description{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #70767C;
            opacity: 0.5;
        }
    }
    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        margin-left: 40px;
        // margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-back{
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #1A1D1F;
        cursor: pointer;
        margin-top: 40px;
        margin-bottom: 24px;
        width: 450px;

        & img{
            margin-right: 33px;
        }
    }

    &-buttons{
        &-container{
            display: flex;
            @media (max-width: 450px) {
                display: block;
            }
        }

        background: #FCFCFC;
        border-radius: 8px;
        width: 100%;
        padding: 24px;
        margin-top: 24px;
        margin-bottom: 8px;

        &-item{
            width: calc(50% - 12px);
            height: 160px;
            @media (max-width: 1400px) {
                height: 160px;
            }

            @media (max-width: 450px) {
                width: 100%;
                margin: auto;
            }

            &.left{
                margin-right: 24px;
            }
        }

        &-line{
            background-color: #F4F4F4;
            height: 160px;
            width: 3px;
            @media (max-width: 450px) {
                height: 3px;
                width: 100%;
            }
        }

        &-icon{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 27px;
            margin-top: 2px;
            display: flex;
            & img{
                width: 48px;
                height: 48px;
                display: block;
            }

            @media (max-width: 850px) {
                display: none;
            }

            @media (max-width: 450px) {
                display: flex;
            }
        }

        &-title{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            padding-left: 5px;
        }

        &-val{
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 65px;
            text-align: left;
            color: #1A1D1F;

            @media (max-width: 1400px) {
                font-size: 50px;
            }

            @media (max-width: 1200px) {
                font-size: 40px;
            }

            @media (max-width: 1050px) {
                font-size: 34px;
            }
        }

        &-position{
            width: 100%;
    
            &-select{
                width: 100%;
                position: relative;
                background: #F4F4F4;
                font-size: 14px;
                border-radius: 8px;
                padding: 12px 12px;
                padding-right: 32px;
                transition: .3s all;
                cursor: pointer;
                position: relative;
                text-align: inherit;

                @media (max-width: 400px){
                    font-size: 12px;
                }

                &.blue{
                    color: #2A85FF;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }

                &.green{
                    color: #83BF6E;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }

                &.red{
                    color: #FF6A55;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }

                &.orange{
                    color: #e8c43e;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }

                &::before{
                    content: url(../../../img/arrow-down-grey.svg);
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 10px;
                    top: calc(50% - 10px);
                    transition: .3s all;
                }
                &.active::before{
                    transform: rotate(180deg);
                }


                
    
                &.active{
                    border-radius:8px 8px 0 0;
                    background: #fff;
                    box-shadow: 0px 2px 16px rgba(26, 29, 31, 0.16);
                }

                &-container{
                    position: relative;

                    &.salaries{
                        min-width: 150px;
                        width: auto;
                        border: 2px solid rgba(112, 118, 124, 0.1);
                        border-radius: 12px;
                        text-align: center;
                        margin-right: 16px;

                        @media (max-width: 400px){
                            min-width: 130px;
                        }

                        
                    }
                }
    
                &-list{
                    width: 100%;
                    max-height: 0px;
                    position: absolute;
                    z-index: 4;
                    border-radius: 0 0 8px 8px;
                    top: 38px;
                    background: #FFFFFF;
                    transition: .3s all;
                    padding: 0 14px 0;
                    font-size: 14px;
                    overflow: hidden;

                    @media (max-width: 400px){
                        font-size: 12px;
                        padding: 0 10px 0;
                    }
    
                    &.active{
                        padding: 5px 14px 10px;
                        max-height: 160px;
                        box-shadow: 0px 16px 16px rgba(26, 29, 31, 0.16);
                        
                        @media (max-width: 400px){
                            padding: 5px 10px 10px;
                        }

                        &.add-event{
                            max-height: 115px;
                        }
                    }
    
                    
    
                    &-scroll{
                        overflow-y: auto;
                        max-height: 140px;
                        padding-right: 5px;
                        padding-left: 5px;

                        &.add-event{
                            max-height: 92px;
                        }
    
                        &::-webkit-scrollbar{
                            width: 10px;            
                        }
                
                        &::-webkit-scrollbar-track {
                            background-color: #E5E5E5;
                            border-radius: 10px;
                            
                        }
                
                        &::-webkit-scrollbar-thumb {
                            background-color: #FCFCFC;
                            border-radius: 10px;
                            border: 2px solid #E5E5E5;
                        }
    
                    }
    
                    &-item{
                        position: relative;   
                        transition: 0.3s;                
                        cursor: pointer;
                        padding: 12px;
                        color: #1A1D1F;
                        margin-bottom: 5px;
                        background-color: #f4f4f4;
                        border-radius: 4px;

                        &.salaries{
                            padding: 8px;
                        }

                        &:hover{
                            color: #fff;
                            background: #1379ff;
                        }

                        &.none{
                            display: none;
                        }
                    }
                }
            }
        }

        &-label{
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            color: #1A1D1F;
            margin-top: 16px;
            margin-bottom: 6px;
            display: block;
    
            &.mt-0{
                margin-top: 0;
            }
        }

        &-before-container{
            position: relative;

            &::before{
                content: url(../../../img/pencil-icon.svg);
                position: absolute;
                width: 20px;
                height: 20px;
                right: 10px;
                top: calc(50% - 10px);
            }
        }
    
        &-input{
            background: #F4F4F4;
            border-radius: 8px;
            padding: 12px;
            height: auto;
            width: 100%;
            font-size: 14px;
            padding-right: 35px;
            transition: .3s all;

            &.focus{
                box-shadow: 0px 0px 0px 1px #1379ff94;
            }

            &::placeholder{
                font-size: 12px;
                color: #b1b1b1;
            }
    
            &.size{
                width: 48%;
                padding: 0;
                padding-left: 12px;
            }
    
            &.zero{
                height: auto;
                display: inline-block;
                width: 90%;
                padding-left: 5px;
            }
    
            &-title{
                font-weight: 500;
                font-size: 14px;
                color: #1A1D1F;
            }
    
            &-fifty{
                width: calc(50% - 8px);
            }
        }
    }

    &-table{
        height: calc(100% - 90px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 1200px) {
            height: calc(100% - 40px);
        }

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: 100%;
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                height: calc(100% - 40px);
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        transition: .3s all;
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &-container{
            margin-top: 8px;
            width: calc(100% - 20px);
            border-bottom: 2px solid #f4f4f4;
            min-width: 730px;

            &.active{
                border-color: #1379ff;
            }
            &:hover{
                border-color: #85baff;
            }
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 40px);
            min-width: 730px;
            padding: 0 0 20px 0;
            border-radius: 0px;
            height: 50px;
            border-bottom: 2px solid #f4f4f4;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
    }

    &-model{
        width: 20%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        padding: 5px 0; /* Поля вокруг текста */
        text-overflow: ellipsis; /* Добавляем многоточие */           
    }

    &-code{
        width: 15%;
    }

    &-staff{
        width: 20%;
    }

    &-date{
        width: 20%;
    }

    &-price{
        width: 15%;
        &.blue{
            color: #1C7FFF;
        }
    }

    &-additionally-open{
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: .3s all;

        &.none{
            opacity: 0;
            cursor: default;
        }

        &.active{
            transform: rotate(180deg);
        }
    }

    &-additionally{
        height: 0;
        transition: .3s;
        overflow: hidden;
        padding-bottom: 0;

        &.active{
            height: 145px;
            margin-bottom: 30px;
        }

        &-item{
            &-label{
                color: #70767C;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 16px;
            }

            &-comment{
                background: #F4F4F4;
                border-radius: 4px;
                width: 100%;
                height: 110px;
                padding: 14px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;

                &-scroll{
                    padding-right: 8px;
                    height: 110px;
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        width: 10px;            
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #E5E5E5;
                        border-radius: 10px;
                        
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #FCFCFC;
                        border-radius: 10px;
                        border: 2px solid #E5E5E5;
                    }
                }
            }
            &.comment{
                width: 50%;
                margin-right: 40px;
                
            }

            &.phone{
                width: 220px;
                margin-right: 64px;                               
            }

            &-phone{
                padding-top: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F; 
            }
        }

    }



}