.warehouse__leaders{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    // min-height: 700px;
    height: calc(100vh - 155px);
    margin-bottom: 20px;
    margin-top: 15px;

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 10px;


        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-bottom: 8px;
        padding-right: 6px;

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding-top:15px;
            padding-right: 8px;
            border-radius: 0px;
            height: 50px;

            .workZone__clients-phone{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-leader-name{
        width: calc(65% - 34px);
        margin-right: 5px; 
    }
    &-envelope-count{
        width: 35%;
        margin-right: 5px; 
    }
    &-open-icon{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}