.spinner {
    display: inline-block;
    animation: rotate 2s linear infinite;
    z-index: 2;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -25px 0 0 -25px;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    
    & .path {
      stroke: #93bfec;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
.moderator{
    &__catalog{
        &-content{
            min-width: 892px;
            height: 720px;
            border-radius: 8px;
            background: #FCFCFC;
            padding: 32px 25px;
            margin-bottom: 10px;

            &-top{
                margin-bottom: 20px;
            }

        }
    
        &-title{
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 40px;
    
            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #B1E5FC;
                border-radius: 4px;
            }
        }

        &-button{
            color: #1C7FFF;
            font-size: 15px;
            font-weight: 500;
            height: 36px;
            cursor: pointer;

            &-icon{
                margin-right: 10px;
            }

            &.add-lekalo{
                margin-right: 60px;
            }

            &.add-folder{
                margin-right: 20px;
            }

        }

        &-lekals-archive-download{
            // display: none;
            border: 2px solid #fcfcfc;
            padding: 6px 10px;
            border-radius: 10px;
            transition: .5s all;
            position: relative;
        
            &-button{
                cursor: pointer;
            }
        
            & input{
                display: none;
            }
        
            &.active{
                // border-left: 2px solid #B1E5FC;
                animation: move 1.5s infinite linear;
            }
            
            @keyframes move {
                0% {
                    border-left: 2px solid #1c7eff7e;
                    border-bottom: 2px solid #1c7eff2d;     
                }
                20% {
                    border-top: 2px solid #1c7eff7e;
                }
                40% {
                    border-right: 2px solid #1c7eff7e;
                }
                80% {
                    border-bottom: 2px solid #1c7eff7e;
                }
                100% {
                    border: 2px solid #fcfcfc;
                }
            }
        
            &-title{
                font-weight: 600;
                font-size: 14px;
                color: #1C7FFF;
            }
        
            &-icon{
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }
        
            &-process{
                &-block{
                    background: #fcfcfc;
                    border-radius: 8px;
                    width: 350px;
                    // padding: 0 20px 0;
                    position: absolute;
                    top: 50px;
                    right: 0;
                    max-height: 0;
                    opacity: 0;
                    transition: .3s all;
                    z-index: 3;
                    overflow: hidden;
        
                    &.active{
                        max-height: 300px;
                        padding: 20px 20px 15px;
                        border: 0.5px solid #F4F4F4;
                        box-shadow: 0px 10px 24px rgb(26 29 31 / 8%);
                        opacity: 1;
                    }

                    &.send{
                        height: 86px;
                    }

                    &.download{
                        height: 86px;
                    }


                    &-step{
                        position: absolute;
                        overflow: hidden;
                        transition: .3s all;
                        top: 20px;
                        width: 100%;
                        padding: 0 20px 0;

                        &.send-leakals-step{
                            left: -350px;

                            &.active{
                                left: 0;
                            }
                        }

                        &.download-leakals-step{
                            right: -350px;

                            &.active{
                                right: 0;
                            }
                        }
                    }
                }
        
                &-top{
                   margin-bottom: 8px; 
                }
        
                &-title{
                    font-weight: 600;
                    font-size: 14px;
                    // margin-bottom: 8px;
                }
        
                &-progress-bar{
                    &-container{
                        width: 100%;
                        height: 12px;
                        border: 1px solid #40434454;
                        border-radius: 6px;
                        background: #f4f4f4;
                        overflow: hidden;
                        // margin-bottom: 20px;
                    }
        
                    height: 10px;
                    border-radius: 5px;
                    background: #3b84e2;
                }
        
                &-buttons{
                    &-container{
                        margin-left: auto;
                        width: auto;
                    }
        
                    background: #1C7FFF;
                    // padding: 4px 8px;
                    padding: 4px;
                    color: #fff;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 8px;
                    // width: 90px;
                    width: 22px;
                    height: 22px;
                    text-align: center;
                    transition: .3s all;
        
                    &-icon{
                        width: 14px;
                        height: 14px;
                    }
        
                    &:hover{
                        background: #1c7effd0;
                    }
        
                    &.pause{
                        margin-right: 12px;
                    }
                }
            }
        }

        &-path{
            color: #90959D;
            padding-bottom: 15px;
            border-bottom: 2px solid #ebebeb;


            &-item{
                margin-right: 4px;
                height: 20px;
                font-weight: 500;
                transition: .3s all;
                cursor: pointer;

                &:hover{
                    color: #3a3a3a;
                }

                &.active{
                    color: #1A1D1F;
                }
            }

            &-icon{
                margin-right: 4px;
                height: 20px;
            }
        }

        &-container{
            height: 570px;
            padding-right: 12px;
            margin-top: 20px;
            overflow-y: scroll;
            // overflow-x: hidden;

            &::-webkit-scrollbar{
                width: 12px;            
            }

            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }

            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 4px solid #E5E5E5;
            }
        }

        &-folder{
            width: 200px;
            padding: 0 5px;
            height: auto;
            margin-bottom: 10px;
            position: relative;

            &.none-click{
                pointer-events: none;
            }

            &-icon{
                width: 110px;
                height: 110px;
                margin: auto;
                display: block;
                position: relative;
                background:url(../../../img/moderator-folder-icon.svg);
                background-size: contain;
                cursor: pointer;
            }

            &-loading{                   
                width: 70px;
                height: 70px;
                position: absolute;
                border-radius: 50%;
                border: 10px solid #9AACB8;
                border-right-color: #2A85FF;
                top: 26px;
                left: calc(50% - 35px);
                -webkit-animation: spin 2s linear infinite;
                /* Safari */
                animation: spin 2s linear infinite;
                
                @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                }
                }
                @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
                }
            }

            &-name{
                margin-top: 10px;
                text-align: center;
                pointer-events: none;
                font-size: 15px;
                font-weight: 500;
                color: #1A1D1F;
                width: 190px;
                height: 70px;
                overflow: hidden;
                resize: none;
                border-radius: 2px;
                border: 2px solid #fcfcfc;

                &.active{
                    pointer-events: inherit;
                    border: 2px solid #cce0ff;
                    background-color: #e4eff9;
                }
            }

            &.hidden{
                visibility: hidden;
                height: 0;
                padding: 0;
                margin: 0;
            }

            &-popup{
                width: 170px;
                height: 82px;
                background: #fff;
                padding: 8px;
                border-radius: 6px;
                font-size: 14px;
                box-shadow: 0px 0px 10px #5e5e5e5e;
                display: none;
                opacity: 0;
                top: 65px;
                right: 0px;
                position: absolute;

                

                &.active{
                    display: block;
                    opacity: 1;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: -20px;
                    right: 100px;
                    border: 10px solid transparent;
                    /* Прозрачные границы */
                    border-bottom: 10px solid #fff;
                    /* Добавляем треугольник */
                }
                &-item {
                    transition: 0.3s all;
                    padding: 8px 8px;
                    cursor: pointer;

                    &.edit {
                        background: url(../../../img/edit-text.svg) no-repeat center right 10px;
                        background-size: 20px;
                        padding-right: 50px;
                    }

                    &.remove {
                        background: url(../../../img/remove.png) no-repeat center right 10px;
                        background-size: 20px;
                        padding-right: 50px;
                        }
                        &:hover {
                        background-color: #ebebeb;
                        border-radius: 4px;
                        }
                }
                
                
            }
        }

        &-lekalo{
            width: 200px;
            padding: 0 5px;
            height: auto;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;

            &-icon{
                width: 110px;
                height: 110px;
                margin: auto;
                display: block;
                background:url(../../../img/moderator-lekalo-icon.svg);
                background-size: contain;
            }

            &-name{
                margin-top: 10px;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
                color: #1A1D1F;
                border-radius: 2px;
            }
        }
    }
    
}


.moderator_catalog-ball-spinner {
    &-container{
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: #9c9c9c6c;
        backdrop-filter: blur(10px);
        display: none;
        transition: .3s all;

        &.active{
            display: block;
        }
    }

    position: absolute;
    width: 30px;
    height: 30px;    
    top: 38px;
    left: 38px;

    &-blob {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 2px solid #f4f4f4;
      
      width: 10px;
      height: 10px;
      border-radius: 50%;
      
      &.top {
        top: 0;
        animation: blob-top 1s infinite ease-in;
      }
      &.bottom {
        top: 100%;
         animation: blob-bottom 1s infinite ease-in;
      }
      &.left {
        left: 0;
        animation: blob-left 1s infinite ease-in;
      }

      &.move-blob {
        background: #f4f4f4;
        top: 0;
        
        animation: blob-spinner-mover 1s infinite ease-in;
      }
    }
    
    
  }
  
  @keyframes blob-bottom {
    25%, 50%, 75% {
      top: 50%;
      left: 100%;
    }
    100% {
      top: 0;
      left: 50%;
    }
  }
  @keyframes blob-left {
    25% {
      top: 50%;
      left: 0;
    }
    50%, 100% {
      top: 100%;
      left: 50%;
    }
  }
  @keyframes blob-top {
    50% {
      top: 0;
      left: 50%;
    }
    75%, 100% {
      top: 50%;
      left: 0;
    }
  }
  
  @keyframes blob-spinner-mover {
    0%, 100% {
      top: 0;
      left: 50%;
    }
    25% {
      top: 50%;
      left: 100%;
    }
    50% {
      top: 100%;
      left: 50%;
    }
    75% {
      top: 50%;
      left: 0;
    }
  }