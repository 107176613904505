.work-zone__workspace{
    padding-top: 15px;

    &-buttons-item{
        width: 100%;
        background: #FCFCFC;
        border-radius: 12px;
        padding: 15px;
        transition: .3s all;
        cursor: pointer;

        &-container{
            width: calc(50% - 4px);
            position: relative;

            &.resize{
                margin-right: 8px;
            }
        }

        &-icon{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &.active{
            border-radius: 12px 12px 0 0;
            box-shadow: 0px 0px 24px rgb(26 29 31 / 6%);
        }

        &-popup{
            width: 100%;
            height: 0px;
            padding:0 24px;
            transition: .3s all;
            background: #FCFCFC;
            border-radius: 0 0 12px 12px;
            position: absolute;
            top: 52px;
            box-shadow: 0px 20px 24px rgb(26 29 31 / 6%);
            overflow: hidden;

            &.active{
                height: 158px;
                padding:24px;
            }

            &-item{
                height: 48px;

                &.one{
                    margin-bottom: 14px;
                }

                &-button{
                    background: #F7F7F7;
                    height: 48px;
                    width: 48px;
                    font-size: 40px;
                    color: #90959D;
                    font-weight: 700;
                    cursor: pointer;

                    &.left{
                        border-radius: 6px 0px 0px 6px;
                    }

                    &.right{
                        border-radius: 0px 6px 6px 0px;
                    }

                    &-icon{
                        position: relative;
                        width: 10px;
                        // height: 10px;

                        &::before{
                            content: '';
                            position: absolute;
                            width: 10px;
                            border-radius: 1px;
                            height: 2px;
                            left: 0;
                            background: #90959D;                            
                        }

                        &.plus::after{
                            content: '';
                            position: absolute;
                            width: 2px;
                            border-radius: 1px;
                            height: 10px;
                            top: -4px;
                            left: 4px;
                            background: #90959D;                            
                        }
                    }
                }

                &-input{
                    width: 100%;
                    height: 48px;
                    border: 1px solid #F4F4F4;
                    border-width: 2px 0 2px 0;
                    font-weight: 500;
                    font-size: 14px;
                    color: #90959D;
                    cursor:text;

                    & div input{
                        color: #000;
                        width: 75px;
                        // padding-left: 65px;
                    }
                }
            }
        }
    }

    &-content{
        width: 100%;
        height: 100%;
        margin-top: 18px;
        border-radius: 12px;
        min-height: calc(100vh - 298px);
        background: #FCFCFC;
        padding: 10px;

        &-lekalo{
            height: 100%;
            max-height: calc(100vh - 320px);
        }
    }

    &-print-btn{
        width: 100%;
        margin-top: 18px;
        padding: 17px;
        background: #1C7FFF;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #FCFCFC;
        transition: .3s all;
        cursor: pointer;

        &:hover{
            background: #1c7fffcc;
        }

        &.deactive{
            pointer-events: none;
            background-color: #1c7fffa3;
        }
    }
}