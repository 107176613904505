.popup{
    &__back{
        width: 100%;
        height: 100%;
        background: #9c9c9c6c;
        backdrop-filter: blur(10px);
        opacity: 0;
        display: none;
        transition: .3s all;
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;

        &.display{
            display: block;
        }

        &.open{
            display: block;
            opacity: 1;
            overflow: hidden;
        }

        &-wrap{
            width: 100%;
            height: 100%;
        }
    }

    &__title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }

        &.orange::before{
            background: #FFBC99;
        }

        &.green::before{
            background: #B5E4CA;
        }

        &.purpure::before{
            background: #CABDFF;
        }

        &.birch::before{
            background: #B5E4CA;
        }

    }

    &__close-btn{
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(../../../img/close-block-icon.svg);
        margin-top: 5px;
    }

    &__error{
        height: 15px;
        width: 100%;
        margin-top: 8px;
        color: #df3535;
        font-size: 12px;
    }
}

.warehouse__popup{
    font-weight: 600;
    font-size: 12px;
    color: #1A1D1F;

    &-not-filled{
        outline: 1px solid #FF6A55;
    }
    &-container{
        width: 498px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(26, 29, 31, 0.1);
        border-radius: 24px;
        padding: 24px;

        &.delite{
            width: 520px;
            padding: 40px 10px;
        }
    }

    &-select{
        width: 100%;
        position: relative;
        background: #F4F4F4;
        height: 41px;
        font-size: 14px;
        border-radius: 8px;
        padding: 12px 12px;
        padding-right: 32px;
        transition: .3s all;
        cursor: pointer;
        position: relative;
        text-align: inherit;

        &.deactive{
            cursor: text;
        }

        &-null{
            font-weight: 500;
            font-size: 12px;
            color: #b1b1b1;
        }

        &::before{
            content: url(../../../img/arrow-down-grey.svg);
            position: absolute;
            width: 16px;
            height: 16px;
            right: 10px;
            top: calc(50% - 8px);
            transition: .3s all;
        }

        &.active::before{
            transform: rotate(180deg);
        }

        &.active{
            border-radius:8px 8px 0 0;
            background: #fff;
            box-shadow: 0px 2px 16px rgba(26, 29, 31, 0.16);
        }

        &-container{
            position: relative;

            &.salaries{
                min-width: 150px;
                width: auto;
                border: 2px solid rgba(112, 118, 124, 0.1);
                border-radius: 12px;
                text-align: center;
                margin-right: 16px;                
            }
        }

        &-list{
            width: 100%;
            max-height: 0px;
            position: absolute;
            z-index: 4;
            border-radius: 0 0 8px 8px;
            top: 38px;
            background: #FFFFFF;
            transition: .3s all;
            padding: 0 14px 0;
            font-size: 14px;
            overflow: hidden;

            &.active{
                padding: 5px 12px 10px 8px;
                max-height: 160px;
                // height: 160px;
                box-shadow: 0px 16px 16px rgba(26, 29, 31, 0.16);
            }

            

            &-scroll{
                overflow-y: auto;
                max-height: 140px;
                padding-right: 8px;
                padding-left: 2px;

                &::-webkit-scrollbar{
                    width: 10px;            
                }
        
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
        
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 2px solid #E5E5E5;
                }

            }

            &-item{
                position: relative;   
                transition: 0.3s;                
                cursor: pointer;
                padding: 12px;
                color: #1A1D1F;
                margin-bottom: 5px;
                background-color: #f4f4f4;
                border-radius: 4px;

                &:hover{
                    color: #fff;
                    background: #1379ff;
                }

                &.none{
                    display: none;
                }
            }
        }
    }

    &-label{
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        color: #70767C;
        margin-top: 16px;
        margin-bottom: 6px;
        display: block;

        &.mt-0{
            margin-top: 0;
        }

        & span{
            color: #FF6A55;
            margin-left: 3px;
            font-size: 16px;

            &.none{
                display: none;
            }
        }
    }

    &-input{
        background: #F4F4F4;
        border-radius: 8px;
        padding: 12px;
        height: auto;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        &::placeholder{
            font-size: 12px;
            color: #b1b1b1;
        }

        &.size{
            width: 48%;
            padding: 0;
            padding-left: 12px;
        }

        &-title{
            font-weight: 500;
            font-size: 14px;
            color: #1A1D1F;
        }

        &-fifty{
            width: calc(50% - 12px);
        }

        &-third{
            width: 30%;
        }
    }

    &-save{
        width: 100%;
        padding: 11px;
        text-align: center;
        background: #1C7FFF;
        border-radius: 8px;
        cursor: pointer;
        transition: .3s all;
        color: #FCFCFC;
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;

        &-icon{
            margin-left: 6px;
        }

        &.deactive{
            pointer-events: none;
            opacity: 0.6;
        }
    }
}