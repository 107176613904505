.moderator__complaints{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 77vh;

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-bottom: 20px;
        margin-left: 40px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }

        @media (max-width: 600px) {
            font-size: 18px;
            margin-left: 30px;

            &::before{
                left: -30px;
            }
        }

        @media (max-width: 320px) {
            font-size: 14px;
            margin-left: 25px;
        }
    }

    &-table{
        height: calc(100% - 45px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 1200px) {
            height: calc(100% - 40px);
        }

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: 100%;
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                height: calc(100% - 40px);
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        transition: .3s all;
        margin-top: 8px;
        width: calc(100% - 20px);
        border-bottom: 2px solid #f4f4f4;
        min-width: 730px;
        cursor: pointer;
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;          
            padding: 0 0 20px 0;
            border-radius: 0px;
            height: 50px;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-address{
        width: 28%;
        margin-right: 5px;          
    }

    &-lekalo{
        width: 30%;
        margin-right: 5px;

        &-icon{
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: block;
        }

        &.title{
            color:#1379ff;
        }

    }

    &-description{
        width: 32%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        text-overflow: ellipsis; /* Добавляем многоточие */       
    }

    &-status{
        width: 8%;
        margin-right: 5px;
        text-align: center;
        
        &-point{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: auto;

            &.green{
                background: #83BF6E;
            }

            &.grey{
                background: #F4F4F4;
            }
        }
    }
         
}

@media (max-width: 1400px) {
    .owner__leaders{    
        &-lekalo{
            width: 20%;
            margin-right: 5px;
            display: block;          
        }
    
        &-status{
            width: 17%;
            margin-right: 5px;
            display: block;                      
        }
    
        &-date{
            width: 17%;
            margin-right: 5px;
            display: block;            
        }
             
    }
}