.owner{
    &-search__zindex{
        z-index: 99;
        width: 62%;

        &.owner-search{
            width: 85%
        }
    }
    &__search{
        width: 100%;
        height: auto;
        padding: 8px 8px 10px 0px;
        border-radius: 12px 12px 0px 0px;
        left: 0;
        position: relative;
        transition: .3s all;
        z-index: 99;
        
        &-blur{
            width: 100% !important;
            min-width: 400px;

            @media (max-width: 840px) {
                min-width: 200px;
                width: auto;
            }

            @media (max-width: 700px) {
                width: 100%;           
                z-index: 0;
                transition: .3s all;
                position: absolute;
                height: 100%;
                top: 0;
                &.active{
                    background: #1a1d1f6e;
                    backdrop-filter: blur(5px);
                    z-index: 20;
                    
                    
                }
            }
        }

        @media (max-width: 700px) {
            margin: auto;
            margin-top: 10px;
            width: 95%;            
        }
        
        &.active{
            background: #FCFCFC;
        }

        &-container{
            width: 100%;            
            border-radius: 12px;
            padding: 0px 15px;
            height: 48px;
            background: #FCFCFC;
            transition: .3s all;
            outline-color: #2a85ff33;
            margin-left: 8px;

            &:focus-within{
                outline: 4px solid #2a85ff33;
            }
    
            &.active{
                outline: 2px solid #2A85FF;                
            }
            
            @media (max-width: 700px) {
                background: #F3F3F3;
                
                &.active{
                    background: #FCFCFC;
                }
                
            }
           
        }

        &-container.active &-close{
            display: block;
        }
        

        &-icon{
           width: 22px;
           height: 22px;
           margin-right: 11px;
           background: url(/img/search-normal.svg);
           background-size: cover;

           
        }
        &:focus-within &-icon{
            background: url(/img/search-blue.svg);
            background-size: cover;
        }        

        &-input, &-input::placeholder{
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #70767C;
        }

        &-input{
            width: calc(100% - 50px);
            color: #1A1D1F;
        }
        
        &-close{
            width: 25px;
            height: 25px;
            margin-left: 10px;
            display: block;
            cursor: pointer;
            display: none;
            
            &-active{
                display: block;
            }
        }

        &-list{
            display: none;
            position: absolute;
            top: 65px;
            left: 0;            
            width: 100%;
            border-radius: 0px 0px 8px 8px;
            z-index: 15;
            background-color: #FCFCFC;
            padding: 24px 14px 26px;
            box-shadow: 0px 20px 30px #1a1d1f1f;

            &.active{
                display: block;
            }
            &-scrol{
                max-height: 200px;
                overflow-y: auto;
                padding-right: 20px;

                &::-webkit-scrollbar{
                    width: 16px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }

                @media (max-width: 700px) {
                    max-height: 400px;
                }
            }

            &-title{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                margin-bottom: 10px;
            }

            &-item{
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #70767C;
                transition: .3s all;
                padding: 12px 8px;
                cursor: pointer;

                &-home-leader{
                    position: relative;
                    display: inline-block;
                    &.active::before{
                        content: "";
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        right: -35px;
                        top: calc(50% - 15px);
                        background: url(/img/check-backgr.svg) no-repeat center;
                        z-index: 1;
                        border-radius: 50%;
                    }
                }

                &-home-points{
                    position: relative;
                    display: inline-block;

                    &-name{
                        margin-bottom: 5px;
                    }
                    
                    &.active::before{
                        content: "";
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        right: -40px;
                        top: calc(50% - 15px);
                        background: url(/img/check-backgr.svg) no-repeat center;
                        z-index: 1;
                        border-radius: 50%;
                    }
                }
                & span{
                    color: #1A1D1F;
                    font-weight: 600;
                }

                &:hover{
                    background: #F4F4F4;
                    border-radius: 8px;
                }
            }

        }
    }         
}