.moderator__leaders-staf-points{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 77vh;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-back{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1A1D1F;
        cursor: pointer;
        margin-top: 40px;
        margin-bottom: 20px;

        & img{
            margin-right: 8px;
        }
    }

    &-table{
        height: calc(100% - 90px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 1200px) {
            height: calc(100% - 40px);
        }

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: 100%;
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                height: calc(100% - 40px);
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        transition: .3s all;
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &-container{
            margin-top: 8px;
            width: calc(100% - 20px);
            border-bottom: 2px solid #f4f4f4;
            min-width: 730px;

            &.active{
                border-color: #1379ff;
            }
            &:hover{
                border-color: #85baff;
            }
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 40px);
            padding: 0 0 20px 0;
            border-radius: 0px;
            height: 50px;
            border-bottom: 2px solid #f4f4f4;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
    }

    &-name{
        width: 20%;
        margin-right: 5px;
    }

    &-address{
        width: 30%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        padding: 5px; /* Поля вокруг текста */
        text-overflow: ellipsis; /* Добавляем многоточие */           
    }

    &-login{
        width: 24%;
    }

    &-password{
        width: 24%;
    }

    &-staf-open{
        display: block;
        width: 28px;
        height: 28px;
        cursor: pointer;

        &.none{
            opacity: 0;
            cursor: default;
        }

        &.active{
            transform: rotate(180deg);
        }
    }

    &-key{
        height: 0;
        transition: .3s;
        overflow: hidden;
        padding-bottom: 0;

        &.active{
            height: 30px;
            margin-bottom: 30px;
        }

        &-label{
            color: #70767C;
            margin-right: 8px;
            font-size: 14px;
            height: 30px;
        }

        &-input{
            background: #F4F4F4;
            border-radius: 4px;
            width: 300px;
            height: 28px;
            padding: 6px 16px;
            white-space: nowrap; /* Запрещаем перенос строк */
            overflow: hidden; /* Обрезаем все, что не помещается в область */
            text-overflow: ellipsis; /* Добавляем многоточие */
            font-weight: 600;
            font-size: 14px;
        }

        &-copy-button, &-refresh-button{
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-left: 10px;
        }

        &-copy-button{
            background: url(../../../../../img/copy-icon.svg) no-repeat;
        }

        &-refresh-button{
            background: url(../../../../../img/refresh-grey-icon.svg) no-repeat;
            transition: .3s all;
            opacity: 0.5;
            &:hover{
                opacity: 1;
            }
        }

    }



}