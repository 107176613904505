.warehouse__home{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    // min-height: 700px;
    height: calc(100vh - 155px);
    margin-bottom: 20px;
    margin-top: 15px;

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 10px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }
    }

    &-top{

        &-error{
            color: #FF6A55;
            margin-right: 20px;
            font-size: 14px;
            font-weight: 500;
        }

        &-send-btn{
            padding: 11px 16px;
            width: 132px;
            height: 40px;
            border: 2px solid #2A85FF;
            background: #2A85FF;
            border-radius: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #FCFCFC;
            cursor: pointer;
            margin-top: -6px;
            margin-right: 16px;
            transition: .3s all;
            opacity: 1;

            &-icon{
                width: 16px;
                height: 16px;
                margin-left: 4px;
                background: url(/img/long-arrow-right-white.svg) center no-repeat;
                transition: .3s all;
            }

            &.none{
                opacity: 0;
                pointer-events: none;
            }

            &.deactive{
                pointer-events: none;
                opacity: 0.5;
            }
        }

        &-transfer-btn{
            padding: 11px 16px;
            width: 118px;
            height: 40px;
            border: 2px solid #F4F4F4;
            border-radius: 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            cursor: pointer;
            margin-top: -6px;
            margin-right: 16px;

            &-icon{
                width: 16px;
                height: 16px;
                margin-left: 9px;
                background: url(/img/transfer-grey-icon.svg) center no-repeat;
                transition: .3s all;
            }

            &.active{
                color: #2A85FF;
                border-color: #2A85FF;
            }

            &.active &-icon{
                background: url(/img/transfer-blue-icon.svg) center no-repeat;
            }
        }

        &-add-btn{
            padding: 11px 16px;
            width: 118px;
            height: 40px;
            border: 2px solid #2A85FF;
            background: #2A85FF;
            border-radius: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #FCFCFC;
            cursor: pointer;
            margin-top: -6px;
            transition: .3s all;

            &-icon{
                width: 16px;
                height: 16px;
                margin-left: 4px;
                background: url(/img/plus-white-icon.svg) center no-repeat;
                transition: .3s all;
            }

            &.opacity{
                color: #F4F4F4;
                background: #fff;
                border-color: #F4F4F4;
                pointer-events: none;
            }

            &.opacity &-icon{
                background: url(/img/plus-grey-icon.svg) center no-repeat;
            }
        }

        &-back{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #1A1D1F;
            cursor: pointer;
    
            & img{
                margin-right: 16px;
            }
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }

        &-line{
            height: 72px;
            margin-top: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            width: calc(100%);
            border-bottom: 2px solid #f4f4f4;
            transition: .3s all;
            min-width: 720px;
            padding-bottom: 8px;
            color: #1A1D1F;

            &-background-status{
                width: 100%;
                height: 100%;
                border-radius: 8px;
                padding: 0 16px;
                transition: .3s all;
    
                &.active{
                    background-color: #FF6A55;
                    color: #FCFCFC;
                }
            }
            
            & a{
                color: #1A1D1F;
            }
    
            &.title{
                font-size: 14px;
                line-height: 17px;
                color: #70767C;            
                width: calc(100% - 20px);
                border-radius: 0px;
                height: 50px;
                padding-right: 8px;
    
                .workZone__clients-phone{
                    text-decoration: none;
                    color: #70767C;
                }
            }
            &.active{
                border-color: #1379ff;
            }

            &.border-red{
                border-color: #FF6A55;
            }
        }
    
        &-check{
            width: 20px;
            height: 20px;
            border-radius: 6px;
            margin: 0 33px 0 2px;
            border: 2px solid #f4f4f4;
            cursor: pointer;
            background: #fff;
            transition: 0.3s;
            background: url(/img/check.svg) center #fff no-repeat;
            background-size: 70%;

            &.active{
                border-color: #2A85FF;
                background: url(/img/check.svg) center #2A85FF no-repeat;
            }
        }

        &-sort-icon{
            width: 18px;
            height: 18px;
            margin-left: 5px;
            margin-top: 3px;
            transition: .3s all;
            opacity: 0;
            transform: rotate(0deg);

            &.ascending{
                opacity: 1;
            }
    
            &.descending{
                opacity: 1;
                transform: rotate(180deg);
            }
        }
    
        &-articul{
            width: calc(30% - 40px);
            margin-right: 5px; 
        }
        &-name{
            width: 40%;
            margin-right: 5px; 
        }
        &-count{
            width: calc(30% - 40px);
            margin-right: 5px;

            & span{
                color: #70767C;
                margin-left: 4px;
            }
            &-input{
                min-width: 1px;
                width: 10px;
                text-align: right;
                font-weight: 600;
                font-size: 14px;
                color: #1A1D1F;
                padding: 0;
                
                &-width{
                    position: absolute;
                    left: -1000px;
                    top: -1000px;
                    opacity: 0;
                    z-index: -1000;
                    font-weight: 600;
                    font-size: 14px;
                }
                &.border-red{
                    border-bottom: 2px solid #FF6A55;
                }
            }
        }
        &-menu{
            &-container{
                width: 24px;
                position: relative;
            }
            &-button{
                width: 24px;
                height: 24px;
                cursor: pointer;
                transition: .3s all;
                background: url(/img/warehouse-menu-icon.svg) center no-repeat;

                &.active, &:hover{
                    background: url(/img/warehouse-menu-icon-active.svg) center no-repeat;
                }
            }
            &-popup{
                position: absolute;
                width: 300px;
                max-height: 0;
                right: 24px;
                top: 28px;
                background: #FCFCFC;
                border-radius: 12px;
                // opacity: 0;
                overflow: hidden;
                transition: transform .3s, max-height .2s;

                &.main{
                    width: 246px;
                    height: 180px;
                }

                &.writeoff{
                    height: 175px;
                }

                &.delete{
                    height: 120px;
                }

                &.active{
                    max-height: 175px;
                    // opacity: 1;
                    box-shadow: 0px 0px 24px rgb(26 29 31 / 8%);
                }

                &-line{
                    width: 100%;
                    height: 2px;
                    background-color: #F4F4F4;
                }

                &-item{
                    font-weight: 500;
                    font-size: 14px;
                    padding: 18px;
                    cursor: pointer;

                    &-icon{
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    &.title{
                        font-size: 16px;                        
                    }

                    &.title &-icon{
                        width: 24px;
                        height: 24px;
                    }

                    &-input{
                        width: 100%;
                        font-size: 14px;
                        font-weight: 500;
                        color: #1A1D1F;
                        padding: 12px;
                        background: #F4F4F4;
                        border-radius: 6px;
                        margin: 6px 0 12px;

                        &::placeholder{
                            color: #D1D2D2;
                        }

                        &-container{
                            padding: 0 18px 0;
                        }
                    }

                    &-title.red{
                        color: #FF6A55;
                    }
                }

                &-step{
                    position: absolute;
                    right: -300px;
                    transition: .3s all;

                    &.main{
                        width: 246px;
                        height: 180px;
                        
                        &.open{
                            left: 0;
                        }
    
                        &.close{
                            left: -246px;
                        }
                    }
                    &.writeoff, &.delete{
                        width: 300px;

                        &.open{
                            right: 0;
                        }
    
                        &.close{
                            right: -300px;
                        }
                    }
                }

                &-button{

                    &-container{
                        width: 100%;
                        padding: 0 18px 10px;
                    }

                    width: calc(50% - 5px);
                    font-size: 14px;
                    padding: 10px 2px;
                    text-align: center;
                    border-radius: 6px;
                    cursor: pointer;
                    &.red{
                        background: #FF6A55;
                        color: #FCFCFC;
                    }

                }
            }
        }
        
        
        
    }

    
}