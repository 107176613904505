.workZone__history{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    // min-height: 700px;
    height: calc(100vh - 155px);
    margin-bottom: 20px;
    margin-top: 15px;

    &-filters{
        position: relative;
        margin-top: -20px;

        &-block{
            position: relative;
        }

        &-title{
            padding: 11px 16px;
            height: 40px;            
            background: #FCFCFC;
            border: 2px solid #F4F4F4;
            border-radius: 12px;
            cursor: pointer;
            color: #70767C;

            &.active{
                background: #2A85FF;
                border: 2px solid #2A85FF;
                color: #fff;
            }

            &-icon{
                display: block;
                margin-left: 12px;
                width: 16px;
                height: 16px;
                background: url(/img/filters-arrow-icon.svg) no-repeat;
                background-size: 16px;
                margin-top: 5px;
            }

            &.active &-icon{
                background: url(/img/filters-arrow-active-icon.svg) no-repeat;
            }
        }
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-bottom: 8px;
        cursor: pointer;
        // padding-right: 15px;

        &-background-status{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            padding: 0 16px;
            transition: .3s all;

            &.active{
                background-color: #FF6A55;
                color: #FCFCFC;
            }
        }
        
        & a{
            color: #1A1D1F;
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding-top:15px;
            border-radius: 0px;
            height: 50px;

            .workZone__clients-phone{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-date{
        width: 19%;
        margin-right: 5px; 
        color: #70767C;
    }

    &-line-background-status.active &-date{
        color: #FCFCFC;
    }

    &-model{
        width: 40%;
        margin-right: 5px; 
    }
    &-staff{
        width: 25%;
        margin-right: 5px; 
    }
    &-price{
        width: 10%;
        margin-right: 5px;
    }

    &-status{
        &-container{
            width: 5%;
        }
        
        width: 10px;
        height: 10px;
        border-radius: 5px;

        &.green{
            background: #83BF6E;
        }
        
        
    }
}

@media (max-width: 1200px) {
    .owner__leaders-staf{
        &-person{
            width: 28%;    
        }  
        &-phone{
            width: 29%;           
        }
    
        &-email{
            width: 45%;            
        }
    
        &-birthday{
            display: none;          
        }
    }
}