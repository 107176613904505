.owner__leaders-staf{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 600px;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-back{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1A1D1F;
        cursor: pointer;
        margin-top: 40px;
        width: 100px;

        & img{
            margin-right: 8px;
        }
    }

    &-top{
        margin-top: 24px;
        margin-bottom: 28px;

        @media (max-width: 1400px) {
            margin-top: 14px;
            margin-bottom: 10px;
        }
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-filters{
        position: relative;
        margin-top: -20px;
        &-title{
            padding: 12px 16px;
            height: 48px;
            background: #2A85FF;
            border-radius: 12px;
            cursor: pointer;
            color: #fff;

            &-icon{
                display: block;
                margin-left: 12px;
                width: 24px;
                height: 24px;
            }
        }
    }

    &-buttons{
        &-container{
            display: flex;
            @media (max-width: 450px) {
                display: block;
            }
        }
        background: #fff;
        border-radius: 8px;
        width: 100%;
        padding: 8px 24px 12px;
        margin-top: 24px;
        margin-bottom: 8px;
        &-sale, &-income, &-purchase{
            width: 32%;
            height: 190px;
            padding: 15px 24px 15px;

            @media (max-width: 1400px) {
                height: 160px;
            }

            @media (max-width: 450px) {
                width: 100%;
                margin: auto;
            }
        }
        &-line{
            background-color: #F4F4F4;
            height: 160px;
            width: 3px;
            @media (max-width: 450px) {
                height: 3px;
                width: 100%;
            }
        }

        &-icon{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 27px;
            margin-top: 2px;
            display: flex;
            & img{
                width: 48px;
                height: 48px;
                display: block;
            }

            @media (max-width: 850px) {
                display: none;
            }

            @media (max-width: 450px) {
                display: flex;
            }
        }

        &-title{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            padding-left: 5px;
        }

        &-val{
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 65px;
            text-align: center;
            color: #1A1D1F;

            @media (max-width: 1400px) {
                font-size: 50px;
            }

            @media (max-width: 1200px) {
                font-size: 40px;
            }

            @media (max-width: 1050px) {
                font-size: 34px;
            }
        }

        &-val-percent{
            margin-top: 25px;
            margin-left: auto;
            padding:  5px 6px;
            height: 24px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;                
            border-radius: 4px;
            width: 95px;

            @media (max-width: 1400px) {
                margin-left: 0px;
                margin-top: 5px;
            }

            &-icon{
                margin-right: 2px;
            }

            &.green{
                background: #83bf6e33;
                color: #83BF6E;
            }

            &.red{
                background: #FF6A551A;
                color: #FF6A55;
            }
        }
    }

    &-table{
        height: 440px;
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 680px;

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            @media (max-width: 900px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-phone, &-email{
        color: #2A85FF;
        text-decoration: underline;

        &.border-none{
            color: #0e0e0e;
            text-decoration: none;
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        cursor: pointer;
        min-width: 680px;
        
        @media (max-width: 700px) {
            height: 62px;
        }
        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 30px);
            padding: 0 0 20px 0;
            border-radius: 0px;
            height: 50px;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-person{
        width: 28%;
        margin-right: 5px;

        &-photo{

            &-container{
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 16px;
                overflow: hidden;
            }

            height: 32px;
            width: auto;
            border-radius: 16px;
            display: block;
        }

    }

    &-phone{
        width: 20%;
        margin-right: 5px;            
    }

    &-email{
        width: 30%;
        margin-right: 5px;            
    }

    &-birthday{
        width: 22%;
        margin-right: 5px;            
    }
}

@media (max-width: 1200px) {
    .owner__leaders-staf{
        &-person{
            width: 28%;    
        }  
        &-phone{
            width: 29%;           
        }
    
        &-email{
            width: 45%;            
        }
    
        &-birthday{
            display: none;          
        }
    }
}