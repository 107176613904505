.autorization{
    background: url(../../img/autorization-back-img.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;

    @media (max-width: 920px){
        background: #FFFFFF;
    }

    &__logo{
        position: absolute;
        width: 100px;
        // height: 136px;
        top: 80px;
        left: 80px;

        @media (max-width: 920px){
            width: 80px;
            left: calc(50% - 40px);
            top: 20px;
            z-index: 2;
        }
    }
    
    &__content{
        background: url(../../img/autorize-back-white.svg) no-repeat;
        background-size: cover;
        width: 45%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        @media (max-width: 1600px){
            max-width: 650px;
            width: 100%;
        }

        @media (max-width: 1270px){
            max-width: 550px;
            width: 100%;
        }

        @media (max-width: 1100px){
            max-width: 450px;
            width: 100%;
        }

        @media (max-width: 920px){
            background: #ffffff;
            max-width: 100%;
        }


        // @media (max-width: 1600px)


        &-form{
            width: 80%;
            max-width: 480px;
            height: 400px;

            @media (max-width: 500px){
                width: 90%;
                max-width: 100%;
            }
            
            &-title{
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                text-align: center;
                margin-bottom: 18px;

                @media (max-width: 600px){
                    font-weight: 600;
                    font-size: 32px;
                }
            }

            &-lable {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 8px;
                margin-top: 24px;
                display: block;
            }

            &-field {
                background: #FFFFFF;
                border: 2px solid #393d3f;
                border-radius: 12px;
                height: 56px;
                padding: 0 15px;
                transition: 0.3s all;

                @media (max-width: 600px){
                    height: 46px;
                    padding: 0 12px;
                }

                &:focus-within {
                    border-color: #2A85FF;
                }

                &-input {
                    width: 100%;
                    height: 100%;
                    font-family: "Inter", sans-serif;

                    &::placeholder {
                        color: #9AACB8;
                        font-size: 14px;
                    }
                }

                &-icon {
                    margin-left: 5px;
                    width: 24px;
                }
            }

            &-checkbox {
                display: none;

                &-label {
                    display: flex;
                    align-items: center;
                    position: relative;
                    transition: 0.3s;
                    min-height: 20px;
                    margin: 18px 0px 12px 37px;
                    width: 100%;
                    text-align: left;
                    font-family: "Inter", sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        background: #fff;
                        width: 20px;
                        height: 20px;
                        left: -33px;
                        transition: 0.3s;
                        border: 2px solid #393d3f;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }

                &:checked + &-label::before {
                    background: url(/img/check.svg) center #2A85FF no-repeat;
                    background-size: 70%;
                    border-color: #2A85FF;
                }
            }

            &-btn {
                width: 100%;
                padding: 17px;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #FCFCFC;
                background: #2A85FF;
                box-shadow: 0px 14px 8px -8px rgba(42, 133, 255, 0.16);
                border-radius: 12px;
                cursor: pointer;
                margin-top: 10px;

                @media (max-width: 600px){
                    padding: 14px;
                }

                @media (max-width: 450px){
                    padding: 10px;
                    margin-top: 5px;
                    font-size: 16px;
                }
            }
        }
    }
}
