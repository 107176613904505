.moderator{
    padding-left: 308px;
    scroll-behavior: smooth;
    &__wrap{
        padding: 24px 40px 0px;
        width: 100%;
    }

    &__title{
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #1A1D1F;
        margin:38px 0 24px;
        @media (max-width: 1600px){
            font-size: 24px;
            line-height: 24px;
            margin: 10px 0 10px;
        }
    }
}