.franchises-leaders__staf{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 700px;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-back{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1A1D1F;
        cursor: pointer;
        margin-top: 40px;
        width: 100px;

        & img{
            margin-right: 8px;
        }
    }

    &-top{
        margin-top: 24px;
        margin-bottom: 28px;

        @media (max-width: 1400px) {
            margin-top: 14px;
            margin-bottom: 10px;
        }
    }

    &-add-leader-btn{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1C7FFF;
        cursor: pointer;
        margin-right: 20px;
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-filters{
        position: relative;
        margin-top: -20px;
        &-title{
            padding: 12px 16px;
            height: 48px;
            background: #2A85FF;
            border-radius: 12px;
            cursor: pointer;
            color: #fff;

            &-icon{
                display: block;
                margin-left: 12px;
                width: 24px;
                height: 24px;
            }
        }
    }

    &-buttons{
        &-container{
            display: flex;
            @media (max-width: 450px) {
                display: block;
            }
        }
        background: #FCFCFC;
        border-radius: 8px;
        width: 100%;
        padding: 10px 24px 24px 24px;
        margin-top: 24px;
        margin-bottom: 8px;
        &-sale, &-income, &-purchase{
            width: calc(50% - 5px);
            height: 178px;
            padding: 40px 40px 32px 40px;
            border-radius: 8px;

            @media (max-width: 1400px) {
                height: 160px;                
            }

            @media (max-width: 640px) {
                padding: 30px 30px 22px 30px;                
            }

            @media (max-width: 490px) {
                padding: 15px 15px 10px 15px;
            }

            @media (max-width: 450px) {
                width: 100%;
                margin: auto;
                padding: 30px 30px 22px 30px;                
            }
        }

        &-sale{
            background: #e7f5ee;
        }

        &-income{
            background: #e6f6fd;
        }

        &-line{
            background-color: #F4F4F4;
            height: 160px;
            width: 3px;
            @media (max-width: 450px) {
                height: 3px;
                width: 100%;
            }
        }

        &-icon{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 27px;
            margin-top: 2px;
            display: flex;
            & img{
                width: 48px;
                height: 48px;
                display: block;
            }

            @media (max-width: 850px) {
                display: none;
            }

            @media (max-width: 450px) {
                display: flex;
            }
        }

        &-title{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            padding-left: 5px;
        }

        &-val{
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 65px;
            text-align: left;
            color: #1A1D1F;

            @media (max-width: 1400px) {
                font-size: 50px;
            }

            @media (max-width: 1200px) {
                font-size: 40px;
            }

            @media (max-width: 1050px) {
                font-size: 34px;
                line-height: 55px;
            }

            @media (max-width: 640px) {
                font-size: 28px; 
                line-height: 40px;             
            }
        }

        &-val-percent{
            margin-top: 8px;
            margin-left: 0;
            padding:  5px 6px;
            height: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;              
            border-radius: 4px;
            width: auto;

            @media (max-width: 1400px) {
                margin-left: 0px;
                margin-top: 5px;
            }

            &-icon{
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-right: 15px;
        
        & a{
            color: #1A1D1F;
        }
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 22px);
            padding: 0 15px 20px 0;
            border-radius: 0px;
            height: 50px;

            .franchises-leaders__staf-phone, .franchises-leaders__staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-person{
        width: 20%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        padding: 5px; /* Поля вокруг текста */
        text-overflow: ellipsis; /* Добавляем многоточие */
    }

    &-login{
        width: 20%;

        &.blue{
            color: #1C7FFF;
        }

        &.green{
            color: #83BF6E;
        }

        &.red{
            color: #FF6A55;
        }
    }

    &-password{
        width: 20%;
    }

    &-phone{
        width: 20%;
        margin-right: 5px; 
        
        color: #1A1D1F;
        text-decoration: none;
    }

    &-email{
        width: 15%;
        margin-right: 5px;

        color: #1A1D1F;
        text-decoration: none;
    }

    &-open{
        display: block;
        width: 28px;
        height: 28px;
        cursor: pointer;

        &.none{
            opacity: 0;
            cursor: default;
        }
    }
}

.edit-icon-block{
    background: none;
    border-radius:0;

    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;

    &.inactively{
        opacity: 0.2;
        pointer-events: none;
        cursor: default;
    }

    & img{
        display: block;
        height: 17px;
    }
    &.none{
        opacity: 0;
        cursor: default;
    }
}

.delite-icon-block{
    background-color: #ff5e5e;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;

    &-center{
        width: 18px;
        position: relative;
    }

    &-line{
        width: 100%;
        height: 3px;
        background-color: #fff;
        border-radius: 2px;
        position: absolute;
        top: calc(50% - 1px);

        &.one{
            transform: rotate(45deg);
        }
        &.two{
            transform: rotate(-45deg);
        }
    }


    &.none{
        opacity: 0;
        cursor: default;
    }
}

@media (max-width: 1200px) {
    .owner__leaders-staf{
        &-person{
            width: 28%;    
        }  
        &-phone{
            width: 29%;           
        }
    
        &-email{
            width: 45%;            
        }
    
        &-birthday{
            display: none;          
        }
    }
}