.work-zone{
    &__header{
        position: relative;
        width: 100%;
        padding-top: 20px;
        padding-right: 15px;

        &-desktop{
            display: flex;
            width: 100%;
            padding: 0 20px;
        }

        &.sidebar-none{
            padding-left: 384px;
            padding-right: 15px;

            @media (max-width: 1600px) {
                padding-left: 300px;
            }
        }

    }    
        
    
    &__buttons{
        width: 320px;
        margin-left: 20px;
        padding-left: auto;


        &-item{
            position: relative;
            margin-right: 24px;
        }

        &-icon{
            width: 40px;
            height: 40px;
            border-radius: 20px;
            transition: .3s all;
            cursor: pointer;

            &.cashbox{
                background: url(/img/work-zone-cashbox-icon.svg) center #FFF no-repeat;
                background-size: 60%;

                &.active{
                    background: url(/img/work-zone-cashbox-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-cashbox-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-cashbox-hover-icon.svg) center #FFF no-repeat;
                }
            }

            &.notifications{
                background: url(/img/work-zone-notification-icon.svg) center #FFF no-repeat;
                background-size: 24px;

                position: relative;

                &::after{
                    content: " ";
                    position: absolute;
                    top: 8px;
                    right: 8.5px;
                    width: 8px;
                    height: 8px;
                    background: #FF6A55;
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                }
                &.active::after, &.notific-none::after{
                    content: none;
                }

                &.active{
                    background: url(/img/work-zone-notification-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-notification-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-notification-hover-icon.svg) center #FFF no-repeat;
                }
            }
        }

        &-popup{
            position: absolute;
            top: 56px;
            right: -180px;
            z-index: 2;
            background: #FCFCFC;
            width: 400px;
            max-height: 420px;
            border: 0.5px solid #F4F4F4;
            box-shadow: 0px 10px 24px rgba(26, 29, 31, 0.08);
            border-radius: 12px;
            padding: 20px 12px;
            transition: .5s all;
            transform: translateY(-700px);
            opacity: 0;

            &-notification-null{
                width: 100%;
                height: 40px;
                font-size: 18px;
                text-align: center;
                color: #5D676F;
            }

            &.user{
                right: 0;
                width: 280px;
                height: 194px;
                padding: 24px;
            }

            &.workZoneCashbox{
                padding: 16px 24px;
                height: auto;
                width: 320px;
                right: -140px;

                // padding: 16px 14px;
                // width: 350px;
                // right: -155px;
            }

            &-workZoneCashbox{
                &-title{
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;                    
                    letter-spacing: -0.02em;
                    color: #1A1D1F;
                    // padding-right: 16px;                    
                    max-width: 170px;
                    text-align: center;
                    transition: .3s all;
                    border: 0px solid #F4F4F4;

                    &.border{
                        border-right: 1px solid #F4F4F4;
                    }
                }
                &-input{
                    width: 168px;
                    padding: 14px;
                    background: #F4F4F4;
                    border-radius: 6px;

                    &-container{
                        width: auto;
                        height: 0px;
                        overflow: hidden;
                        transition: .3s all;

                        &.active{
                            height: 44px;
                            margin-top: 14px;
                        }
                    }
                }

                &-button{
                    width: 120px;
                    height: 44px;
                    // position: absolute;
                    // right: 14px;
                    // bottom: 14px;
                    background: #FCFCFC;
                    // border: 1px dashed #F4F4F4;                    
                    // border-radius: 6px;
                    // margin-right: 16px;
                    font-weight: 600;
                    font-size: 32px;
                    // font-size: 14px;
                    color: #0AD48E;
                    cursor: pointer;

                    &-icon{
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                }
            }

            &.workZoneNotification{
                padding: 18px 16px;
                padding-right: 10px;
                height: auto;
                width: 360px;
                right: -190px;
            }

            &-workZoneNotification{
                &-title{
                    font-weight: 600;
                    font-size: 20px;
                    color: #1A1D1F;
                    margin-bottom: 28px;
                }

                &-list{
                    max-height: 345px;
                    
                    &-scroll{
                        max-height: 330px;
                        width: 100%;
                        padding-right: 8px;
                        overflow-y: auto;
                        overflow-x: hidden;

                        &::-webkit-scrollbar{
                            width: 10px;            
                        }
                        &::-webkit-scrollbar-track {
                            background-color: #E5E5E5;
                            border-radius: 10px;
                            
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: #FCFCFC;
                            border-radius: 10px;
                            border: 2px solid #E5E5E5;
                        }
                    }

                    &-item{
                        margin-bottom: 16px;
                        &-container{
                            width: calc(100% - 30px);
                        }

                        &-circle{
                            width: 14px;
                            height: 14px;
                            margin-right: 16px;
                            border-radius: 50%;
                            background: #F4F4F4;

                            &.active{
                                background: #B1E5FC;
                            }

                        }

                        &-date{
                            font-weight: 500;
                            font-size: 14px;
                            color: #70767C;
                            margin-bottom: 4px;
                            line-height: 1;
                        }

                        &-text{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #1A1D1F;
                        }
                    }

                }


            }

            &-user{
                
                &-item{
                    border-bottom: 1px solid #F4F4F4;
                    padding-bottom: 19px;
                    // width: 230px;
                    width: 100%;

                    &.download-drivers{
                        position: relative;
                        padding-top: 19px;
                    }
                }

                &-drivers{
                    &-title{
                        font-size: 14px;
                        font-weight: 600;
                        color: #5D676F;
                        cursor: pointer;
                    }

                    &-list{
                        position: absolute;
                        top: 48px;
                        left: 0;
                        width: 100%;
                        padding: 0 8px 0 16px;
                        max-height: 200px;
                        height: 0;
                        background: #FCFCFC;
                        border: 1px solid #FFFFFF;
                        transition: .3s all;
                        overflow: hidden;
                        box-shadow: 0px 8px 24px rgba(26, 29, 31, 0.06);
                        border-radius: 12px;

                        &.active{
                            height: 200px;
                            padding: 10px 8px 10px 16px;
                        }

                        &-scroll{
                            max-height: 180px;
                            padding-right: 8px;
                            overflow-y: scroll;
                            overflow-x: hidden;

                            &::-webkit-scrollbar{
                                width: 8px;            
                            }
                            &::-webkit-scrollbar-track {
                                background-color: #E5E5E5;
                                border-radius: 10px;
                                
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: #FCFCFC;
                                border-radius: 10px;
                                border: 2px solid #E5E5E5;
                            }
                        }

                        &-item{
                            width: 100%;
                            padding: 15px 0;
                            border-bottom: 1px solid #F4F4F4;

                            &-name{
                                width: calc(100% - 24px);
                            }

                            &-icon{
                                width: 24px;
                                height: 24px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                &-photo{
                    width: 18px;
                    height: 24px;
                    display: block;
                    margin-right: 11px;
                }
                &-name{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                }

                &-logout{
                    cursor: pointer;
                    padding-top: 19px;
                      &-icon{
                          margin-right: 10px;
                      }
                      &-title{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FF6A55;
                      }  
                }
            }

            &.active{
                transform: translateY(0px);
                opacity: 1;
            }

            &-title{
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 500;
                padding-left: 8px;
            }

            &-btn{
                width: 100%;
                padding: 11px;
                text-align: center;
                background: #1C7FFF;
                border-radius: 8px;
                cursor: pointer;
                transition: .3s all;
                color: #FCFCFC;
                font-size: 14px;
                font-weight: 500;
                margin-top: 16px;
            }
        }

        &-user{
            cursor: pointer;
            &.mobile{
                margin: auto;
                width: 95%;
                padding: 16px 24px;
                background: #F4F4F4;
                border-radius: 16px;
                margin-top: auto;
            }

            &-photo{
                width: 18px;
                height: 24px;
                display: block;

                &-background{
                    width: 48px;
                    height: 48px;
                    border-radius: 24px;
                    background-color: #FCFCFC;
                    margin-right: 17px;
                }
            }
            &-name{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                
                & span{
                    white-space: nowrap; /* Запрещаем перенос строк */
                    overflow: hidden; /* Обрезаем все, что не помещается в область */
                    text-overflow: ellipsis; /* Добавляем многоточие */
                    width: 100px;
                }

                &-verify{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #5D676F;
    
                    &-icon{
                        width: 15px;
                        height: 15px;
                        display: block;
                    }
                }
            }
            &-button{
                margin-left: 10px;
                width: 15px;
                height: 7px;
                display: block;
                cursor: pointer;
            }
            
        }
    }
}