.moderator__popup{
    &-key{
        width: calc(100% - 30px);
        color: #1a1d1f;

        &-copy-button{
            width: 24px;
            height: 24px;
            cursor: pointer;
            background: url(../../../../img/copy-icon.svg) no-repeat;
            background-size: 100%;
        }
    }
}