.owner{
    &__purchase{
        background-color: #fff;
        border-radius: 8px;
        width: 100%; 
        padding: 28px 24px;
        min-height: 600px;
        &-top{
            margin-bottom: 15px;
            padding: 0px 24px;

            @media (max-width: 600px) {
                padding: 0;
            }
        }

        &-title{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 15px;
            
            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #B1E5FC;
                border-radius: 4px;
            }

            @media (max-width: 1400px) {
                font-size: 22px;
                margin-left: 5px;
                &::before{
                    left: -30px;
                }
            }

            @media (max-width: 500px) {
                font-size: 16px;
                margin-left: 20px;
                &::before{
                    left: -30px;
                }
            }
        }

        &-filters{
            position: relative;
            &-title{
                padding: 12px 16px;
                height: 48px;
                background: #FCFCFC;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                margin-left: 8px;
                cursor: pointer;

                &-icon{
                    display: block;
                    margin-left: 12px;
                    width: 15px;
                    height: 7px;
                }
            }
        }
        &-content{
            height: auto;
        }
        &-table{
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100% - 62px);
            // padding: 0px 4px 0px 20px;
            margin-top: 10px;
            min-width: 450px;

            @media (max-width: 700px) {
                min-height: 300px;
                height: 100%;
                overflow-y: hidden;
            }

            &-scroll{
                overflow-y: hidden;
                overflow-x: hidden;
                margin-bottom: 20px;

                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }

                @media (max-width: 550px) {
                    overflow-x: scroll;
                }
            }

            &::-webkit-scrollbar{
                width: 16px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 4px solid #E5E5E5;
            }
            &-line{
                height: 72px;
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                width: 100%;
                transition: .3s all;
                border-bottom: 2px solid #f4f4f4;
                min-width: 450px;
                
                &.title{
                    font-size: 14px;
                    line-height: 17px;
                    height: 60px;
                    color: #70767C;
                    border-bottom: 2px solid #f4f4f4;
                    width: calc(100% - 25px);
                    // margin-right: 24px;
                    // margin: 0 24px 8px 20px;
                    border-radius: 0px;
    
                }

                &.active{
                    border-color: #1379ff;
                }

    
                &:hover{
                    border-color: #85baff;
                }

                @media (max-width: 1200px) {
                    font-size: 16px;
                }

                @media (max-width: 700px) {
                    height: 50px;
                    // font-size: 15px;
                }
            }
    
            &-buyer{
                width: 44%;
                margin-right: 5px;
    
                &-photo{

                    &-container{
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                        border-radius: 16px;
                        overflow: hidden;
                    }
        
                    height: 32px;
                    width: auto;
                    border-radius: 16px;
                    display: block;
                }
    
            }
    
            &-quantity{
                width: 28%;
                margin-right: 5px;
                text-align: center;            
            }
    
            &-summ{
                width: 28%;
                text-align: center;            
            }
        }
    }         
}