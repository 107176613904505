.franchises-leaders__warehouse{
    &-content{
        flex-wrap: wrap;
    }
}

.franchises__warehouse-table-menu{
    &-container{
        width: 24px;
        position: relative;
    }
    &-button{
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: .3s all;
        background: url(/img/warehouse-menu-icon.svg) center no-repeat;

        &.active, &:hover{
            background: url(/img/warehouse-menu-icon-active.svg) center no-repeat;
        }
    }
    &-popup{
        position: absolute;
        width: 300px;
        max-height: 0;
        right: 24px;
        top: 28px;
        background: #FCFCFC;
        border-radius: 12px;
        // opacity: 0;
        overflow: hidden;
        transition: transform .3s, max-height .2s;

        &.main{
            width: 246px;
            height: 120px;
        }

        &.writeoff, &.addedEnvelope{
            height: 175px;
        }

        &.delete{
            height: 120px;
        }

        &.active{
            max-height: 175px;
            // opacity: 1;
            box-shadow: 0px 0px 24px rgb(26 29 31 / 8%);
        }

        &-line{
            width: 100%;
            height: 2px;
            background-color: #F4F4F4;
        }

        &-item{
            font-weight: 500;
            font-size: 14px;
            padding: 18px;
            cursor: pointer;

            &-icon{
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            &.title{
                font-size: 16px;                        
            }

            &.title &-icon{
                width: 24px;
                height: 24px;
            }

            &-input{
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                color: #1A1D1F;
                padding: 12px;
                background: #F4F4F4;
                border-radius: 6px;
                margin: 6px 0 12px;

                &::placeholder{
                    color: #D1D2D2;
                }

                &-container{
                    padding: 0 18px 0;
                }
            }

            &-title.red{
                color: #FF6A55;
            }
        }

        &-step{
            position: absolute;
            right: -300px;
            transition: .3s all;

            &.main{
                width: 246px;
                height: 180px;
                
                &.open{
                    left: 0;
                }

                &.close{
                    left: -246px;
                }
            }
            &.writeoff, &.addedEnvelope, &.delete{
                width: 300px;

                &.open{
                    right: 0;
                }

                &.close{
                    right: -300px;
                }
            }
        }

        &-button{

            &-container{
                width: 100%;
                padding: 0 18px 10px;
            }

            width: calc(50% - 5px);
            font-size: 14px;
            padding: 10px 2px;
            text-align: center;
            border-radius: 6px;
            cursor: pointer;
            &.red{
                background: #FF6A55;
                color: #FCFCFC;
            }

            &.blue{
                background: #2A85FF;
                color: #FCFCFC;
            }


        }
    }
}