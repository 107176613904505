.filters{
  max-width: 480px;
  width: 100%;
  background: #FCFCFC;
  // box-shadow: 0px 0px 48px rgba(26, 29, 31, 0.06);
  border-radius: 12px;
  padding: 30px 24px 24px;
  max-height: 95vh;
  padding-bottom: 25px;
  
  &__scroll{
    padding: 5px;
    max-height: calc(95vh - 200px);
    overflow-y: auto;
    @media (max-height: 860px) {
      overflow-y: scroll;
    }
    &::-webkit-scrollbar{
      width: 10px;            
      }
  
      &::-webkit-scrollbar-track {
          background-color: #E5E5E5;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
}
  
      &::-webkit-scrollbar-thumb {
          background-color: #FCFCFC;
          border-radius: 10px;
          border: 2px solid #E5E5E5;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
}
  }
  

  

  &__container{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #292a303f;
    left: 0;
    top: 0;
    opacity: 0;
    transition: .3s opacity;
    display: flex;
    z-index: 99;

    &.close{
      display: none;
    }

    &.open{
      opacity: 1;
      display: flex;
    }
  }

  &__top{
    padding-bottom: 30px;
    border-bottom: 2px solid #F4F4F4;
    margin-bottom: 24px;
  }

  &__block{
    display: flex;
    
    @media (max-width: 450px) {
      display: block;
    }
    
    &-item{
      position: relative;
      margin-bottom: 30px;
      margin-right: 8px;
      z-index: 99;
      
      @media (min-width: 1500px) {
        display: none;
      }

      @media (max-width: 450px) {
        margin-bottom: 10px;
      }

      &-title{
        padding: 12px 16px;
        height: 48px;
        width: 180px;
        background: #FCFCFC;
        border: 2px solid #F4F4F4;
        border-radius: 12px;
        cursor: pointer;
        transition: .3s;

        @media (max-width: 450px) {
          width: 90%;
        }

        &-icon{
          background: url(../../img/arrow-down.svg) center no-repeat;
          margin-left: 12px;
          background-size: 15px;
          width: 15px;
          height: 9px;
          transition: .3s;
          margin-top: 5px;
        }

        &.active, &.open{
          background: #2A85FF;
          color: #fff;
        }

        &.active &-icon, &.open &-icon{
          background: url(../../img/arrow-down-white.svg) center no-repeat;
          background-size: 15px;
        }
        
        &.open &-icon{
            transform: rotate(180deg);
        }

      }
      
      &-list{
        width: 100%;
        height: 0;
        overflow: hidden;
        padding: 0px 2px 0px 12px;
        top: 55px;
        left: 0;
        position: absolute;
        background-color: #fff;
        border: 0px solid #F4F4F4;
        box-shadow: 5px 5px 10px #5d676f7a;
        border-radius: 12px;
        transition: .3s all;

        &.open{
          height: 300px;
          padding: 12px 2px 12px 12px;
          border-width: 2px;
        }

        &-elem{
          width: 100%;
          padding: 7px 12px;
          font-size: 16px;
          font-weight: 500;
          border-radius: 8px;
          margin-bottom: 4px;
          cursor: pointer;
          transition: .3s all;

          &:hover, &.active{
            background-color: #2A85FF;
            color: #fff;
          }
        }

        &-scroll{
          overflow-y: scroll;
          width: 100%;
          height: 276px;
          padding-right: 6px;

          &::-webkit-scrollbar{
            width: 10px;            
          }

          &::-webkit-scrollbar-track {
              background-color: #E5E5E5;
              border-radius: 10px;
              
          }

          &::-webkit-scrollbar-thumb {
              background-color: #FCFCFC;
              border-radius: 10px;
              border: 2px solid #E5E5E5;
          }
        }
      }
    }
  }

  &__range{
    cursor: pointer;
    max-width: 150px;
    transition: .3s;
    margin-bottom: 20px;
    display: flex;

    @media (min-width: 1500px) {
      display: none;
    }

    &-checkbox{
      width: 24px;
      height: 24px;
      border: 2px solid #70767C;
      border-radius: 8px;
      margin-right: 10px;
      transition: .3s;
      background: url(../../img/check-white.svg) center no-repeat;
    }

    

    &.active &-checkbox{
      background-color: #2A85FF;
      background-size: 12px;
      border-color: #2A85FF;
    }


    &-title{
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #70767C;
    }    
  }

  &__title{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    margin-left: 40px;

    &::before{
        content: "";
        position: absolute;
        width: 16px;
        height: 40px;
        left: -40px;
        top: -6px;
        background: #FFBC99;
        border-radius: 4px;
    }
  }

  &__close-btn{
    width: 27px;
    height: 27px;
    background: url(../../img/close-circle-gray.svg) center;
    background-size: 29px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #FCFCFC;
    transition: .1s all;
    &:hover{
      border-color: #70767C;
    }
  }
  &__buttons{
    margin-top: 16px;
    margin-left: auto;
    &-item{
      padding: 12px 16px;
      height: 46px;
      background: #FCFCFC;
      border: 2px solid #F4F4F4;
      border-radius: 12px;      
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #5D676F;
      cursor: pointer;
      transition: .3s;

      &.reset{
        margin-left: auto;
        margin-right: 8px;
      }
      &.accept{
        background: #2A85FF;
        color: #fff;
      }
    }
  }
}

.filters__calendar-container{
  position: relative;
  // padding-bottom: 32px;
  // border-bottom: 2px solid #F4F4F4;
  
  &.off{
    opacity: 0.5;
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  
}

@media (max-height: 860px) {
  .filters{
    padding: 20px;
  
    &__top{
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  
    &__block{
      
      &-item{
        margin-bottom: 10px;
  
        &-title{
          padding: 8px 16px;
          width: 180px;
          height: auto;
        }
        
        &-list{

          &-elem{
            font-size: 16px;
          }
        }
      }
    }
  
    &__range{
      margin-bottom: 10px;  
    }
  
    &__title{
      font-size: 18px;
    }
    &__buttons{
      margin-top: 14px;
      margin-left: auto;
      &-item{
        padding: 6px 16px;
        font-size: 16px;
        height: auto;
      }
    }
  }
}



.react-calendar {
  width: 100%;
  min-height: 420px;
  line-height: 1.125em;
  margin: auto;
  font-family: 'Inter';
  color: #1A1D1F;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  & button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled:hover {
      cursor: pointer;
    }
  }

  .react-calendar--doubleView {
    width: 900px;

    &.react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      & > * {
        width: 50%;
        margin: 0.5em;
        width: 450px;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;
    display: flex;

    & button {
      min-width: 44px;
      background: none;

      &[disabled] {
        background-color: #a33131;
      }
    }

    &__arrow{
      font-size: 50px;
    }

    &__label{
      font-size: 15px;
      transition: 0.5s;
      width: 200px !important;

      &:hover{
        color: #111d29;
      }

      &__labelText{
        padding-top: 6px;
        display: block;
        font-family: 'Inter';
        font-weight: 600;
        color: #1A1D1F;
      }

      &::first-letter{
        text-transform: uppercase;
      }
    }

    &__arrow{
      color: #1A1D1F !important;
      font-size: 40px;
      line-height: 10px;
      width: 60px;
      text-align: center;
      font-family: 'Black Han Sans', sans-serif;
      transition: .3s all;

      &:hover{
        font-size: 40px !important;
        color: #111d29 !important;
      }
    }

    &__prev2-button, &__next2-button{
      display: none;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 15px;
    background: none;
    margin-top: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    color: #1A1D1F;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
  
    &.react-calendar__month-view__days__day abbr{
      display: block;
      width: 15px;
      height: 15px;
    }

    &:disabled {
      background-color: #f0f0f0;
    }

    &:enabled:hover,
    &:enabled:focus {
      background: #2A85FF;
      transition: 0.3s;
      color: #fff;
    }

    .react-calendar__tile--hasActive {
      background: #76baff;
      
      &:enabled:hover,
      &:enabled:focus {
        background: #2A85FF;
      }      
    }

  }

  &__month-view{
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
  
      &__weekday {
        padding: 0.5em;
  
        & abbr{
          padding: 0.5em;
          font-family: 'Inter';
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #70767C;
          text-decoration: none;
        
        }
      }
    }

    &__weekNumbers {
      font-weight: bold;

      & .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }
  }

  &__range{
    width: 100%;
    padding: 0 20px;
    margin-top: 10px;
    
    &-item{
      width: 45%;
    }
    
    &-label{
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #70767C;
      margin-bottom: 4px;
      margin-left: 5px;
    }

    &-value{
      background: #F4F4F4;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #1A1D1F;
      padding: 14px;
    }
  }
}

.react-calendar--selectRange .react-calendar__tile--hover{
  background-color: #2986ff36;
  color: #1A1D1F;
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #70767C;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2A85FF;
}

.react-calendar__tile--range, .react-calendar__tile--range:enabled:hover{
  background-color: #2986ff36;
  color: #1A1D1F;

  &.react-calendar__tile--rangeStart:enabled:hover, &.react-calendar__tile--rangeEnd:enabled:hover{
    background-color: #2A85FF;
    color: #fff;
  }

}

.react-calendar__tile--rangeStart{
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #2A85FF;
  color: #fff;    
}

.react-calendar__tile--rangeEnd{
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #2A85FF;
  color: #fff;
}

.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd{
  border-radius: 8px 0px 0px 8px;
}