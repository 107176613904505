.warehouse__order-history{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    // min-height: 700px;
    height: calc(100vh - 155px);
    margin-bottom: 20px;
    margin-top: 15px;

    &-top{
        &-history-btn{
            padding: 11px 16px;
            width: 118px;
            height: 40px;
            border: 2px solid #2A85FF;
            border-radius: 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #2A85FF;
            cursor: pointer;
            margin-top: -6px;
            margin-right: 16px;

            &-icon{
                width: 16px;
                height: 16px;
                margin-left: 9px;
                background: url(/img/warehouse-history-blue-icon.svg) center no-repeat;
                transition: .3s all;
            }
        }

        &-back{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #1A1D1F;
            cursor: pointer;
            margin-bottom: 14px;
    
            &-icon{
                width: 24px;
                height: 24px;
                cursor: pointer;
                // margin-right: 11px;
    
                &-container{
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-right: 11px;
                }
            }
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-bottom: 8px;
        padding-right: 6px;

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding-top:15px;
            padding-right: 8px;
            border-radius: 0px;
            height: 50px;

            .workZone__clients-phone{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
    }

    &-date{
        width: calc(15%);
        margin-right: 5px;
        color: #70767C;
    }

    &-order-number{
        width: calc(20%);
        margin-right: 5px; 
    }

    &-point{
        width: calc(30% - 95px);
        margin-right: 5px; 
    }

    &-envelope-count{
        width: calc(20% - 95px);
        margin-right: 5px; 
    }

    &-status{
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;

        &.orange{
            color: #FF9466;
        }

        &.blue{
            color: #2A84FE;
        }

        &.green{
            color: #83BF6E;
        }

        &-menu{
            &-container{
                width: 90px;
                margin-right: 60px;
                position: relative;
            }
            &-button{
                width: 24px;
                height: 24px;
                cursor: pointer;
                transition: .3s all;
                background: url(/img/warehouse-menu-icon.svg) center no-repeat;
    
                &.active, &:hover{
                    background: url(/img/warehouse-menu-icon-active.svg) center no-repeat;
                }
            }
            &-popup{
                position: absolute;
                width: 246px;
                max-height: 0;
                right: 45px;
                top: 28px;
                background: #FCFCFC;
                border-radius: 12px;
                opacity: 0;
                overflow: hidden;
                padding: 0 18px;
                z-index: 1;
                transition: .3s all;
    
                &.active{
                    padding: 4px 18px;
                    opacity: 1;
                    max-height: 160px;
                    box-shadow: 0px 0px 24px rgb(26 29 31 / 8%);
                }

                &-item{
                    padding: 12px 0;
                    border-bottom: 1px solid #F4F4F4;
                    font-weight: 500;
                    font-size: 14px;
                    cursor: pointer;

                    &.orange{
                        color: #FF9466;
                    }

                    &.blue{
                        color: #2A84FE;
                    }

                    &.green{
                        color: #83BF6E;
                    }

                    &.border-none{
                        border: none;
                    }

                    &-icon{
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    &-open-icon{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

}