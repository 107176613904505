.calendar{
  max-width: 380px;
  background: #FCFCFC;
  box-shadow: 0px 0px 48px rgba(26, 29, 31, 0.2);
  border-radius: 12px;
  padding: 15px 25px;
  position: absolute;
  top: 40px;
  right: 0;
  overflow: hidden;
  // height: 0;
  transition: .3s all;
  opacity: 0;

  &.open{
    opacity: 1;
  }
  
  &__scroll{
    padding: 5px;
    height: calc(80vh - 85px);
    overflow-y: auto;
    @media (max-height: 860px) {
      overflow-y: scroll;
    }
    &::-webkit-scrollbar{
      width: 12px;            
    }

    &::-webkit-scrollbar-track {
        background-color: #E5E5E5;
        border-radius: 12px;
        
    }

    &::-webkit-scrollbar-thumb {
        background-color: #FCFCFC;
        border-radius: 12px;
        border: 2px solid #E5E5E5;
    }
  }

  &__block{
    display: flex;
    
    @media (max-width: 450px) {
      display: block;
    }
  }

  &__title{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    margin-left: 40px;

    &::before{
        content: "";
        position: absolute;
        width: 16px;
        height: 40px;
        left: -40px;
        top: -6px;
        background: #FFBC99;
        border-radius: 4px;
    }
  }

  &__close-btn{
    width: 27px;
    height: 27px;
    background: url(../../../img/close-circle-gray.svg) center;
    background-size: 29px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #FCFCFC;
    transition: .1s all;
    &:hover{
      border-color: #70767C;
    }
  }

  &__time{
    &-label{
      font-weight: 500;
      font-size: 14px;
      color: #70767C;
      margin-bottom: 8px;

      &.none{
        display: none;
      }
    }

    &-input{
      width: 18px;
      padding: 12px 0;
      text-align: right;

      &-opacity.active{
        opacity: 0.5;
      }

      &-block{
        width: 50px;
        height: 38px;
        background: #F4F4F4;
        border-radius: 8px;
        padding: 0 10px;
      }
      &-span{
        width: 4px;
        font-size: 12px;
        line-height: 15px;
        color: #D1D2D2;

      }
    }

    &-container{

      &.none{
        display: none;
      }

      & span{
        display: block;
        text-align: center;
        width: 12px;
        height: 38px;
        line-height: 38px;
  
        
      }
    }

    &-checkbox{
      width: 20px;
      height: 20px;
      border: 1.5px solid #D1D2D2;;
      border-radius: 6px;
      margin-left: 18px;
      transition: .3s;
      background: url(../../../img/check-white.svg) center no-repeat;
      cursor: pointer;

      &.active{
        background-color: #2A85FF;
        background-size: 12px;
        border-color: #2A85FF;
      }
    }
  }

  &__buttons{
    margin-top: 16px;
    margin-left: auto;

    &.top-none{
      margin-top: 0;
    }
    &-item{
      padding: 11px 16px;
      height: 40px;
      background: #FCFCFC;
      border: 2px solid #F4F4F4;
      border-radius: 12px;      
      font-weight: 500;
      font-size: 14px;
      width: calc(50% - 10px);
      color: #5D676F;
      cursor: pointer;
      transition: .3s;
      text-align: center;

      &.reset{
        margin-left: auto;
        margin-right: 8px;
      }
      &.accept{
        background: #2A85FF;
        color: #fff;
      }
    }
  }
}

.react-calendar {
  width: 100%;
  min-height: 360px;
  line-height: 0.5em;
  margin: auto;
  font-family: 'Inter';
  color: #1A1D1F;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  & button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled:hover {
      cursor: pointer;
    }
  }

  .react-calendar--doubleView {
    width: 900px;

    &.react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      & > * {
        width: 50%;
        margin: 0.5em;
        width: 450px;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;
    display: flex;

    & button {
      min-width: 44px;
      background: none;

      &[disabled] {
        background-color: #a33131;
      }
    }

    &__arrow{
      font-size: 50px;
    }

    &__label{
      font-size: 15px;
      transition: 0.5s;
      width: 200px !important;

      &:hover{
        color: #111d29;
      }

      &__labelText{
        padding-top: 6px;
        display: block;
        font-family: 'Inter';
        font-weight: 600;
        color: #1A1D1F;
      }

      &::first-letter{
        text-transform: uppercase;
      }
    }

    &__arrow{
      color: #1A1D1F !important;
      font-size: 40px;
      line-height: 10px;
      width: 60px;
      text-align: center;
      font-family: 'Black Han Sans', sans-serif;
      transition: .3s all;

      &:hover{
        font-size: 40px !important;
        color: #111d29 !important;
      }
    }

    &__prev2-button, &__next2-button{
      display: none;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 12px;
    background: none;
    margin-top: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    color: #1A1D1F;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  
    &.react-calendar__month-view__days__day abbr{
      display: block;
      width: 15px;
      height: 15px;
    }

    &:disabled {
      background-color: #f0f0f0;
    }

    &:enabled:hover,
    &:enabled:focus {
      background: #2A85FF;
      transition: 0.3s;
      color: #fff;
    }

    .react-calendar__tile--hasActive {
      background: #76baff;
      
      &:enabled:hover,
      &:enabled:focus {
        background: #2A85FF;
      }      
    }

  }

  &__month-view{
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
  
      &__weekday {
        padding: 0.5em;
  
        & abbr{
          padding: 0.5em;
          font-family: 'Inter';
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #70767C;
          text-decoration: none;
        
        }
      }
    }

    &__weekNumbers {
      font-weight: bold;

      & .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }
  }

  &__range{
    width: 100%;
    padding: 0 20px;
    margin-top: 10px;
    
    &-item{
      width: 45%;
    }
    
    &-label{
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #70767C;
      margin-bottom: 4px;
      margin-left: 5px;
    }

    &-value{
      background: #F4F4F4;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #1A1D1F;
      padding: 14px;
    }
  }
}

.react-calendar--selectRange .react-calendar__tile--hover{
  background-color: #2986ff36;
  color: #1A1D1F;
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #70767C;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2A85FF;
  border-radius: 6px;
}

.react-calendar__tile--range, .react-calendar__tile--range:enabled:hover{
  background-color: #2986ff36;
  color: #1A1D1F;
  border-radius: 6px;

  &.react-calendar__tile--rangeStart:enabled:hover, &.react-calendar__tile--rangeEnd:enabled:hover{
    background-color: #2A85FF;
    color: #fff;
    border-radius: 6px;
  }

}

.react-calendar__tile--rangeStart{
  border-radius: 6px;
  background-color: #2A85FF;
  color: #fff;    
}

.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd{
  border-radius: 6px;
}