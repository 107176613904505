.franchises-leaders{
    &__sales{
       background-color: #fff;
       border-radius: 8px;
       width: 100%; 
       margin-bottom: 10px;
       padding: 28px 24px 28px 24px;

        &-filters__block{
            z-index: 1;
                
            &-item{
                position: relative;
                margin-right: 8px;
                z-index: 10;

                @media (max-width: 1500px) {
                    display: none;
                }
                 
                &-title{
                    padding: 8px 12px;
                    height: auto;
                    width: 130px;
                    background: #FCFCFC;
                    border: 2px solid #F4F4F4;
                    border-radius: 12px;
                    cursor: pointer;
                    transition: .3s;
            
                    &-icon{
                        background: url(../../../../img/arrow-down.svg) center no-repeat;
                        margin-left: 12px;
                        background-size: 15px;
                        width: 15px;
                        height: 9px;
                        transition: .3s;
                        margin-top: 2px;
                    }
            
                    &.active, &.open{
                        background: #2A85FF;
                        color: #fff;
                    }
            
                    &.active &-icon, &.open &-icon{
                        background: url(../../../../img/arrow-down-white.svg) center no-repeat;
                        background-size: 15px;
                    }
                    
                    &.open &-icon{
                        transform: rotate(180deg);
                    }
        
                }
                
                &-list{
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    padding: 0px 2px 0px 12px;
                    top: 55px;
                    left: 0;
                    position: absolute;
                    background-color: #fff;
                    border: 0px solid #F4F4F4;
                    box-shadow: 5px 5px 10px #5d676f7a;
                    border-radius: 12px;
                    transition: .3s all;
            
                    &.open{
                        height: 300px;
                        padding: 12px 2px 12px 12px;
                        border-width: 2px;

                        &.staff-page{
                            height: 170px;
                        }
                    }
            
                    &-elem{
                        width: 100%;
                        padding: 7px 12px;
                        font-size: 16px;
                        font-weight: 500;
                        border-radius: 8px;
                        margin-bottom: 4px;
                        cursor: pointer;
                        transition: .3s all;
            
                        &:hover, &.active{
                        background-color: #2A85FF;
                        color: #fff;
                        }
                    }
            
                    &-scroll{
                        overflow-y: scroll;
                        width: 100%;
                        height: 276px;
                        padding-right: 6px;

                        &.staff-page{
                            height: 146px;
                        }
            
                        &::-webkit-scrollbar{
                        width: 10px;            
                        }
            
                        &::-webkit-scrollbar-track {
                            background-color: #E5E5E5;
                            border-radius: 10px;
                            
                        }
            
                        &::-webkit-scrollbar-thumb {
                            background-color: #FCFCFC;
                            border-radius: 10px;
                            border: 2px solid #E5E5E5;
                        }
                    }
                }
            }
              
        }
        

        &-title{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 40px;
            margin-right: 10px;
            min-width: 175px;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #B1E5FC;
                border-radius: 4px;
            }

            @media (max-width: 500px) {
                font-size: 14px;
                margin-left: 20px;
                min-width: auto;
                &::before{
                    left: -30px;
                }
            }
        }
        &-calendar{
            position: absolute;
            top: 40px;
            left: -600px;
            width: 900px;
            border: 2px solid #83BF6E;
            z-index: 99;
        }
        &-filters{
            position: relative;
            &-title{
                padding: 8px 12px;
                // height: 30px;
                background: #FCFCFC;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                cursor: pointer;
                transition: .3s all;

                &-icon{
                    background: url(../../../../img/arrow-down.svg) center no-repeat;
                    margin-left: 12px;
                    background-size: 15px;
                    width: 15px;
                    height: 9px;
                    transition: .3s;
                    margin-top: 2px;
                }

                &.active{
                    background: #2A85FF;
                    color: #fff;
                }

                &.active &-icon{
                    background: url(../../../../img/arrow-down-white.svg) center no-repeat;
                    background-size: 15px;
                }

                @media (max-width: 600px) {
                    height: 36px;
                    font-size: 14px;
                    padding: 12px;
                }
            }
        }

        &-buttons{
            background: #F4F4F4;
            border-radius: 8px;
            width: 100%;
            height: 190px;
            padding: 8px;
            margin-top: 40px;
            cursor: pointer;

            @media (max-width: 700px) {
                flex-direction: column;
            }
            

            &-sale, &-income{
                border-radius: 8px;
                width: 50%;
                height: 100%;
                padding: 30px 12px 16px 35px;
                transition: .5s all;

                &.active{
                    background-color: #fff;
                }

                @media (max-width: 1200px) {
                    padding: 20px 6px 16px 10px;
                }
            }

            @media (max-width: 700px) {
                flex-direction: column;
                height: 320px;
                &-sale, &-income{
                    width: 100%;
                    height: 50%;
                }
            }

            &-icon{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 27px;
                margin-top: 2px;
                display: flex;
                & img{
                    width: 44px;
                    height: 44px;
                    display: block;
                }

                &.sale{
                    background: #CABDFF;
                }

                &.income{
                    background: #B1E5FC;
                }

                @media (max-width: 1200px) {
                    margin-right: 15px;
                }
                @media (max-width: 700px) {
                    display: none;
                }
            }

            &-title{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                padding-left: 5px;
            }

            &-val{
                font-style: normal;
                font-weight: 600;
                font-size: 45px;
                line-height: 65px;
                text-align: center;
                color: #1A1D1F;

                @media (max-width: 1400px) {
                    font-size: 50px;
                }

                @media (max-width: 1200px) {
                    font-size: 40px;
                }

                @media (max-width: 1050px) {
                    font-size: 34px;
                }
            }

            &-val-percent{
                width: 75px;
                margin-top: auto;
                margin-left: auto;
                padding:  5px 6px;
                height: 24px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;                
                border-radius: 4px;
                
                @media (max-width: 1400px) {
                    margin-left: 0px;
                }

                &-icon{
                    margin-right: 2px;
                }

                &.green{
                    background: #83bf6e33;
                    color: #83BF6E;
                }

                &.red{
                    background: #FF6A551A;
                    color: #FF6A55;
                }
            }
        }

        // &-graphik{
        //     width: 100%;
        //     height: 433px;
        //     border-radius: 8px;
        //     margin-top: 10px;
        //     padding-bottom: 2px;
        //     position: relative;

        //     overflow-x: auto;
        //     &::-webkit-scrollbar{
        //         width: 12px;            
        //     }
        //     &::-webkit-scrollbar-track {
        //         background-color: #E5E5E5;
        //         border-radius: 10px;
                
        //     }
        //     &::-webkit-scrollbar-thumb {
        //         background-color: #FCFCFC;
        //         border-radius: 10px;
        //         border: 4px solid #E5E5E5;
        //     }
        // }        
    }  
    &__points{
        &-container{
            margin-top: 30px;
            height: 500px;

            @media (max-width: 700px) {
                height: auto;
            }
        }
        &-checkbox{
            display: flex;
            align-items: center;
            position: relative;   
            transition: 0.3s;
            height: 20px;                
            cursor: pointer;
            width: 20px;
            margin-right: 34px;

            &::before{
                content: '';
                position: absolute;
                background: #fff;
                width: 20px; 
                height: 20px;
                left: 0px;
                transition: 0.3s;
                border: 1.5px solid #393d3f;
                border-radius: 7px;
                cursor: pointer;
            }

            &.active::before{
                background: url(/img/check.svg) center #2A85FF no-repeat;
                background-size: 70%;
                border-color: #2A85FF;
            }
        }

        &-line{
            height: 72px;
            // border-radius: 8px;
            // margin: auto;
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            width: calc(100% - 24px);;
            // padding: 0px 18px;
            transition: .3s all;
            border-bottom: 1px solid #F4F4F4;
            
            &.title{
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                border-bottom: 2px solid #f4f4f4;
                width: calc(100% - 36px);
                padding: 0px;
                // margin: auto;
                // margin-right: 10px;
                margin-bottom: 8px;
                border-radius: 0px;
                padding-bottom: 15px;
                height: auto;

            }

            &.active{
                border-color: #2A85FF;
            }

            @media (max-width: 1200px) {
                font-size: 16px;
            }

            @media (max-width: 700px) {
                height: 50px;                
            }
        }
        &-table{
            height: calc(100% - 60px);
            @media (max-width: 700px) {
                min-height: 300px;
                height: 100%;
            }

            &-scroll{
                height: 100%;

                overflow-y: scroll;
                overflow-x: hidden;
                &::-webkit-scrollbar{
                    width: 10px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 2px solid #E5E5E5;
                }
            }

            &-buttons{
                width: 84px;
                margin: auto;

                &-item{
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                    pointer-events: none;

                    &.back{
                        background: url(./../../../../img/pages-back-button.svg);
                        transition: .3s all;
                        &.active{
                            background: url(./../../../../img/pages-back-button-active.svg);
                            pointer-events:inherit;
                        }
                    }

                    &.forward{
                        background: url(./../../../../img/pages-forward-button.svg);
                        transition: .3s all;
                        &.active{
                            background: url(./../../../../img/pages-forward-button-active.svg);
                            pointer-events:inherit;
                        }
                    }
                }
            }
        }

        &-open-icon{
            width: 20px;
            height: 25px;
        }

        &-name{
            width: calc(50% - 20px);
            margin-right: 5px;
        }

        &-address{
            width: calc(50% - 30px);
            margin-right: 5px;
        }
    }
}

.TestLoad{
    width: 100%;
    height: 433px;
    border-radius: 8px;
    background-color: #83BF6E;
}


@media (max-width: 1400px) {
    
}