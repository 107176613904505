.warehouse{
    &__header{
        position: relative;
        width: 100%;
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 40px;

        &-desktop{
            display: flex;
            width: 100%;
        }

    }
    &__logo{
        width: 35px;
        height: 48px;
    }  
        
    
    &__buttons{
        width: auto;
        margin: 0 48px;
        padding-left: auto;

        &-item{
            position: relative;
            margin-right: 24px;

            &.mr-none{
                margin-right: 0;
            }
        }

        &-icon{
            width: 40px;
            height: 40px;
            border-radius: 20px;
            transition: .3s all;
            cursor: pointer;

            &.leader{
                background: url(/img/work-zone-clients-icon.svg) center #FFF no-repeat;
                background-size: 22px;

                &.active{
                    background: url(/img/work-zone-clients-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-clients-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-clients-hover-icon.svg) center #FFF no-repeat;
                    
                }
            }

            &.home{
                background: url(/img/warehouse-home-button-icon.svg) center #FFF no-repeat;
                background-size: 24px;

                &.active{
                    background: url(/img/warehouse-home-button-icon-active.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/warehouse-home-button-icon-active.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/warehouse-home-button-icon.svg) center #FFF no-repeat;
                }
            }
        }

        &-popup{
            position: absolute;
            top: 68px;
            right: -180px;
            z-index: 2;
            background: #FCFCFC;
            width: 400px;
            max-height: 420px;
            border: 0.5px solid #F4F4F4;
            box-shadow: 0px 10px 24px rgba(26, 29, 31, 0.08);
            border-radius: 12px;
            padding: 20px 12px;
            transition: .5s all;
            transform: translateY(-700px);
            opacity: 0;

            &.user{
                right: 0;
                width: 240px;
                max-height: 194px;
                padding: 24px;
            }

            &-user{
                
                &-item{
                    border-bottom: 1px solid #F4F4F4;
                    padding-bottom: 19px;
                    width: 100%;
                }
                &-photo{
                    width: 18px;
                    height: 24px;
                    display: block;
                    margin-right: 11px;
                }
                &-name{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                }

                &-logout{
                    cursor: pointer;
                    padding-top: 19px;
                      &-icon{
                          margin-right: 10px;
                      }
                      &-title{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FF6A55;
                      }  
                }
            }

            &.active{
                transform: translateY(0px);
                opacity: 1;
            }

            &-title{
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 500;
                padding-left: 8px;
            }

            &-btn{
                width: 100%;
                padding: 11px;
                text-align: center;
                background: #1C7FFF;
                border-radius: 8px;
                cursor: pointer;
                transition: .3s all;
                color: #FCFCFC;
                font-size: 14px;
                font-weight: 500;
                margin-top: 16px;
            }
        }

        &-user{
            &-container{
                width: auto;
                padding-left: auto;
                position: relative;
                margin-left: 48px;
            }
            cursor: pointer;
            &.mobile{
                margin: auto;
                width: 95%;
                padding: 16px 24px;
                background: #F4F4F4;
                border-radius: 16px;
                margin-top: auto;
            }

            &-photo{
                width: 18px;
                height: 24px;
                display: block;

                &-background{
                    width: 48px;
                    height: 48px;
                    border-radius: 24px;
                    background-color: #FCFCFC;
                    margin-right: 17px;
                }
            }
            &-name{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                
                & span{
                    white-space: nowrap; /* Запрещаем перенос строк */
                    overflow: hidden; /* Обрезаем все, что не помещается в область */
                    text-overflow: ellipsis; /* Добавляем многоточие */
                    width: 100px;
                }

                &-verify{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #5D676F;
    
                    &-icon{
                        width: 15px;
                        height: 15px;
                        display: block;
                    }
                }
            }
            &-button{
                margin-left: 10px;
                width: 15px;
                height: 7px;
                display: block;
                cursor: pointer;
            }
            
        }
    }
}