.franchises-leaders{
    &__warehouse-right{
        background-color: #fff;
        border-radius: 8px;
        width: calc(50% - 4px);
        min-width: 360px;
        padding: 28px 24px;
        height: 77vh;
        @media (max-width: 1010px) {
            margin-top: 15px;
            width: 100%;
            min-width: auto;
        }

        @media (max-width: 700px) {
           height: auto;
        }
    }
    &__lekals-top{

        &-top{
            margin-bottom: 30px;

            &-transfer-btn{
                padding: 11px 16px;
                width: 118px;
                height: 40px;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                cursor: pointer;
                margin-top: -6px;

                &-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 9px;
                }
            }
        }
        &-scroll{
            height: calc(100% - 140px);
            @media (max-width: 500px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                overflow-y: hidden;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 10px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 2px solid #E5E5E5;
                }
            }
        }

        &-title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            position: relative;
            margin-left: 36px;
            margin-bottom: 0;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #FFBC99;
                border-radius: 4px;
            }

            &.purpure::before{
                background: #CABDFF;
            }

            @media (max-width: 1200px) {
                font-size: 20px;
                margin-left: 17px;
                &::before{
                    left: -28px;
                }
            }

            @media (max-width: 500px) {
                font-size: 16px;
                margin-left: 20px;
                min-width: auto;
                &::before{
                    left: -30px;
                }
            }
        }
        &-filters{
            margin-top: -6px;
            position: relative;
            &-title{
                padding: 12px 16px;
                height: 48px;
                background: #FCFCFC;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                margin-left: 8px;
                cursor: pointer;
                padding-right: 20px;

                &-icon{
                    display: block;
                    margin-left: 12px;
                    width: 15px;
                    height: 7px;
                }
            }
        }

        &-line{
            padding: 20px 0;
            border-radius: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #1A1D1F;
            min-width: 0px;
            width: 100%;
            border-bottom: 2px solid #F4F4F4;

            &.title{
                border-bottom: 2px solid #F4F4F4;
                padding-bottom: 29px;
                padding-top: 0;
                height: auto;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                border-radius: 0;
                padding-right: 0;
                width: calc(100% - 24px);
            }

            &.active{
                border-color: #1379ff;
            }

            @media (max-width: 700px) {
                font-size: 16px;
                padding: 10px 0;
            }
        }

        &-name{
            width: 35%;
            margin-right: 5px;
            text-align: left;
            &.blue{
                color: #1C7FFF;
            }
        }

        &-coll{
            width: 30%;
            text-align: left;
            
        }

        &-transfer{
            width:24px;
            height: 24px;
            cursor: pointer;

            &.none{
                opacity: 0;
            }
        }

        &-list{
            overflow-y: scroll;
            overflow-x: hidden;
            height: 100%;
            margin-top: 10px;
            min-width: 360px;
            padding-right: 12px;
            &::-webkit-scrollbar{
                width: 10px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }
            
            @media (max-width: 700px) {
                overflow-y: hidden;
                min-height: 210px;
                height: 100%;
            }
            
        }
    }
         
}