.moderator__popup-lekalo-edit{
    &-btn{
        width: 178px;
        height: 167px;
        border: 2px dashed #d1d2d2;
        border-radius: 16px;

        &.active{
            border: none;
        }

        &-container{
            margin-top: 37px;
            margin-bottom: 4px;
        }

        &-input{
            display: none;
        }

        &-label{
            width: 178px;
            height: 167px;
            cursor: pointer;
            font-size: 34px;
            color: #d1d2d2;
            position: relative;

            &::before{
                content: '';
                bottom: 16px;
                right: 16px;
                width: 24px;
                height: 24px;
                background: url(../../../../img/download-icon.svg);
                position: absolute;
            }

            & img{
                width: 101px;
                height: 112px;
            }

            &.active{    
                &::before{
                    bottom: 16px;
                    right: 26px;
                    width: 32px;
                    height: 32px;
                    background: url(../../../../img/refresh-icon.svg) center #fff no-repeat;
                    background-size: 24px;
                    position: absolute;
                    box-shadow: 0px 2px 8px rgba(26, 29, 31, 0.05);
                    border-radius: 8px;
                    cursor: pointer;
                }
    
                & img{
                    width: 101px;
                    height: 112px;
                }
            }
        }

        &.active &-label{
            &::before{
                bottom: 16px;
                right: 26px;
                width: 32px;
                height: 32px;
                background: url(../../../../img/refresh-icon.svg) center #fff no-repeat;
                background-size: 24px;
                position: absolute;
                box-shadow: 0px 2px 8px rgba(26, 29, 31, 0.05);
                border-radius: 8px;
                cursor: pointer;
            }

            & img{
                width: 101px;
                height: 112px;
            }                
        }

        &-container.edit &{
            border: none;
            &-label{    
                &::before{
                    bottom: 16px;
                    right: 26px;
                    width: 32px;
                    height: 32px;
                    background: url(../../../../img/refresh-icon.svg) center #fff no-repeat;
                    background-size: 24px;
                    position: absolute;
                    box-shadow: 0px 2px 8px rgba(26, 29, 31, 0.05);
                    border-radius: 8px;
                    cursor: pointer;
                }
    
                & img{
                    width: 101px;
                    height: 112px;
                }
            }
        }        
    }
}