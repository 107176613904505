.franchises-leaders{
    &__header{
        position: relative;
        width: 100%;

        &-desktop{
            display: flex;
            width: 100%;
            padding: 0 40px;
            @media (max-width: 700px) {
                display: none;
            }
        }

        &-mobile{
            display: flex;
            height: 80px;
            padding: 0 43px;
            width: 100%;
            background: #FCFCFC;
            
            &-container{
                width: 100%;
                position: relative;
            }
            
            @media (min-width: 700px) {
                display: none;
            }
            
            &-logo{
                display: block;
                width: 32px;
                height: 42px;
            }

            

            &-menu{
                background-color: #FCFCFC;
                // border-top: 2px solid #F4F4F4;
                // min-height: calc(100vh - 140px);
                height: 100%;
                width: 100%;
                position: absolute;
                top: 80px;
                z-index: 11;
                transform: translateX(-120%);
                transition: .6s all;
                padding-top: 90px;

                &.active{
                    transform: translateX(0);
                }
            }

            &-border{
                width: 100%;
                height: 2px;
                background: #F4F4F4;
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        &-burger{
            cursor: pointer;
            position: relative;
            height: 22px;
            width: 22px;
            &-item{
                width: 25px;
                height: 2px;
                border-radius: 2px;
                background: #292D32;
                transition: .3s all;
                &.center{
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }

            &.active &-item{
                position: absolute;
                top: 9px;
                &.center{
                    display: none;
                }
                &.top{
                    transform: rotate(135deg);
                }
                &.bottom{
                    transform: rotate(-135deg);
                }
            }
        }
    }
    
    &__buttons{
        padding-top: 8px;
        margin-left: auto;
        padding-left: auto;
        width: 300px;
        &-notifications{
            width: 40px;
            height: 40px;
            background: #FCFCFC;
            border-radius: 50%;
            cursor: pointer;
        }

        &-notifications{
            margin-right: 24px;
            transition: .3s all;
            position: relative;            
                // &.active::after{
                //     content: " ";
                //     position: absolute;
                //     top: 8px;
                //     right: 8.5px;
                //     width: 8px;
                //     height: 8px;
                //     background: #FF6A55;
                //     border: 2px solid #FFFFFF;
                //     border-radius: 50%;
                // }
            &-icon{
                width: 24px;
                height: 24px;                
            }
        }

        &-user{
            &.mobile{
                margin: auto;
                width: 95%;
                padding: 16px 24px;
                background: #F4F4F4;
                border-radius: 16px;
                margin-top: auto;
            }

            &-photo{
                height: 48px;

                &-container{
                    width: 48px;
                    height: 48px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 24px;
                    margin-right: 17px; 
                }
            }
            &-name{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                &-verify{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #5D676F;
    
                    &-icon{
                        width: 15px;
                        height: 15px;
                        display: block;
                    }
                }
            }
            &-button{
                margin-left: 10px;
                width: 15px;
                height: 7px;
                display: block;
                cursor: pointer;
            }
            
        }
    }
}

@media (max-width: 1400px) {
    .franchises-leaders{   
        &__buttons{
            padding-top: 8px;
            margin-left: auto;
            padding-left: auto;
            width: 300px;
            &-notifications{
                width: 40px;
                height: 40px;
                background: #FCFCFC;
                border-radius: 50%;
                cursor: pointer;
            }
    
            &-notifications{
                margin-right: 24px;
                transition: .3s all;
                position: relative;            
                    // &.active::after{
                    //     content: " ";
                    //     position: absolute;
                    //     top: 8px;
                    //     right: 8.5px;
                    //     width: 8px;
                    //     height: 8px;
                    //     background: #FF6A55;
                    //     border: 2px solid #FFFFFF;
                    //     border-radius: 50%;
                    // }
                &-icon{
                    width: 24px;
                    height: 24px;                
                }
            }
    
            &-user{
                
                &.mobile{
                    width: 93%;
                    background: #F4F4F4;
                    margin: 0 auto;
                    height: 80px;
                    // margin: auto;
                    margin-bottom: 20px;
                    border-radius: 16px;
                }
                
                &-photo{
                    width: 48px;
                    height: 48px;
                }
                &-name{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    &-verify{
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #5D676F;
        
                        &-icon{
                            width: 15px;
                            height: 15px;
                            display: block;
                        }
                    }
                }
                &-button{
                    margin-left: 10px;
                    width: 15px;
                    height: 7px;
                    display: block;
                    cursor: pointer;
                }
                
            }
        }
    }
}

.test{
    height: 67%;
    width: 100%;
}