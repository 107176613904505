@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    border: none;
    background: none;
    padding: none;
    list-style: none;
    text-decoration: none;
    outline: none;
    font-family: 'Inter', sans-serif;
}

body{
    color: #1A1D1F;
    max-width: 100vw;
    min-width: 100%;
    min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.d-flex{
    display: flex;
}

.justify-between{
    justify-content: space-between;
}

.justify-around{
    justify-content: space-around;
}

.justify-evenly{
    justify-content: space-evenly;
}

.justify-center{
    justify-content: center;
}

.justify-end{
    justify-content: end;
}

.align-center{
    align-items: center;
}

.flex-column{
    flex-direction: column;
}

.flex-wrap{
    flex-wrap: wrap;
}

.m-auto{
    margin: auto;
}
.ml-auto{
    margin-left: auto;
}

.mr-10{
    margin-right: 10px;
}

.mr-16{
    margin-right: 16px;
}

.mr-20{
    margin-right: 20px;
}

.mr-30{
    margin-right: 30px;
}

.mb-20{
    margin-bottom: 20px;
}

.mb-30{
    margin-bottom: 30px;
}

.mb-35{
    margin-bottom: 35px;
}

.mb-8{
    margin-bottom: 8px;
}

.mt-20{
    margin-top: 20px;
}

.mt-15{
    margin-top: 15px;
}

.pr-10{
    padding-right: 10px;
}
.pl-10{
    padding-left: 10px;
}

.d-block{
    display: block;
}
.pos-relative{
    position: relative;
}
.d-none{
    display: none;
}

body{
    background: #f4f4f4;
}

.none-click{
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.cursor{
    cursor: pointer;
}

.grey{
    color: #9b9b9b;
    cursor: default;
}

.ds-none{
    display: none;
}

.none{
    cursor: default;
    pointer-events: none;
    opacity: 0;
}

// a{
//     font-family: 'Inter', sans-serif;
// }
