.franchises-leaders{
    &__sidebar{
        position: fixed;
        width: 384px;
        min-height: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: #FCFCFC;
        margin-bottom: 40px;
        padding-right: 44px;

        @media (max-width: 1600px) {
            width: 300px;
            padding-right: 10px;
        }

        @media (max-width: 700px) {
            display: none;
        }
    }

    &__logo{
        width: 56px;
        height: 77px;
        margin: 29px 0 40px 28px;
        @media (max-width: 1600px) {
            margin: 29px 0px 0px 28px;
        }
    }

    &__nav{
        position: relative;
        &-item{
            width: 100%;
            height: 48px;
            margin: 0 24px 8px;
            position: relative;
            border-radius: 12px;
            padding: 16px 12px;
            transition: .3s all;
            cursor: pointer;
            color: #646C75;


            &-active{
                width: 100%;
                height: 48px;
                margin: 0 24px 8px;
                position: absolute;
                border-radius: 12px;
                padding: 16px 12px;
                transition: .4s all;
                background: #1C7FFF;
                z-index: 0;

                &.Home{
                    top: 0;
                }
                &.Staff{
                    top: 56px;
                }
                &.Warehouse, &.Points-mold{
                    top: 112px;
                }
                &.Salaries{
                    top: 168px;
                }
                &.Staff-Detail, &.PointPrinting{
                   display: none;
                }

                @media (max-width: 1600px) {
                    width: 90%;
                    mqrgin-right: 0px;
                }

                @media (max-width: 1400px) {
                    border-radius: 6px;
                    width: 32px;
                    @media (max-height: 450px) {
                        &.Home{
                            top: 0;
                        }
                        &.Staff{
                            top: 40px;
                        }
                        &.Warehouse, &.Points-mold{
                            top: 80px;
                        }
                        &.Salaries{
                            top: 120px;
                        }
                    }
                }
            }

            &-title{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                &.active{
                    color: #FCFCFC;
                }
            }

            &-icon{
                width: 24px;
                height: 24px;
                margin-right: 8px;

                &.home{
                    background: url(/img/franchise-leaders-points-icon.svg) no-repeat center;
                    background-size: contain;
                    transition: .3s all;
                    &.active{
                        background: url(/img/franchise-leaders-points-active-icon.svg) no-repeat center;
                    }
                }
                &.staff{
                    background: url(/img/franchise-leaders-staff-icon.svg) no-repeat center;
                    background-size: contain;
                    transition: .3s all;
                    &.active{
                        background: url(/img/franchise-leaders-staff-active-icon.svg) no-repeat center;
                    }
                }
                &.warehouse{
                    background: url(/img/franchise-leaders-warehouses-icon.svg) no-repeat center;
                    background-size: contain;
                    transition: .3s all;
                    &.active{
                        background: url(/img/franchise-leaders-warehouses-active-icon.svg) no-repeat center;
                    }
                }
                &.salaries{
                    background: url(/img/franchise-leaders-salaries-icon.svg) no-repeat center;
                    background-size: contain;
                    transition: .3s all;
                    &.active{
                        background: url(/img/franchise-leaders-salaries-active-icon.svg) no-repeat center;
                    }
                }
            }

        }
    }

    &__sidebar-footer{
        position: absolute;
        bottom: 34px;
        width: 100%;
        height: 40px;

        &.header-footer{
            position: relative;
            height: 175px;
        }

        @media (max-height: 700px) {
            @media (max-width: 700px) {
                position: static;
            }
        }
    }

    &__logout{
        margin: 0 24px;
        padding-left: 18px;
        width: calc(100% - 48px);
        margin-bottom: 24px;
        padding-bottom: 26px;
        border-bottom: 2px solid #ebebeb;
        cursor: pointer;
          &-icon{
              margin-right: 10px;
          }
          &-title{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #FF6A55;
          }  
    }
         
}

@media (max-width: 1400px) {
    .franchises-leaders{
        &__sidebar{
            width: 104px;
            padding-right: 0;
            margin-bottom: 40px;
        }
    
        &__logo{
            width: 48px;
            height: 66px;
            margin: 29px auto 40px;
            display: block;

            @media (max-height: 450px) {
                margin: 12px auto 12px;
            }
        }
    
        &__nav{
            &-item{
                width: 56px;
                margin: 0 24px 8px;
                padding: 14px 18px;
    
                &-active{
                    width: 56px;
                    padding: 14px 18px;
                }
    
                &-title{
                    display: none;
                }
    
                &-icon{
                    width: 20px;
                    height: 20px;
                    margin: auto;
                }

                @media (max-height: 450px) {
                    width: 32px;
                    margin: 0 24px 8px;
                    padding: 6px;
                    height: 32px;

                    &-active{
                        width: 32px;
                        height: 32px;
                        padding: 14px 15px;
                        margin: 0 36px 8px;
                    }

                }
            }
        }
    
        &__sidebar-footer{    
            @media (max-height: 450px) {
                bottom: 2px;
            }
        }

        &__logout{
            padding-left: 0px;
            &-icon{
                margin: auto;
                display: block;
            }
                &-title{
                display: none;
            } 
              
            @media (max-height: 450px) {
                padding-bottom: 6px;
            }
        }
             
    }
    
}

@media (max-width: 700px) {
    .franchises-leaders{    
        &__nav{
            width: 100%;
            &-item{
                width: calc(95% - 24px);
                
                padding: 16px 12px;
    
                &-active{
                    width: calc(95% - 24px);
                    padding: 16px 12px;
                }
    
                &-title{
                    display: block;
                }
    
                &-icon{
                    width: 24px;
                    height: 24px;
                    margin: 0 8px 0 0;
                }
    
            }
        }
    
        &__logout{
            padding-left: 0px;
              &-icon{
                margin: 0 10px 0 0;
                display: block;
              }
              &-title{
                display: block;
              }  
        }
             
    }
    
    
    
}