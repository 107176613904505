.warehouse__leader-detail{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    // min-height: 700px;
    height: calc(100vh - 155px);
    margin-bottom: 20px;
    margin-top: 15px;

    &-top{
        &-history-btn{
            padding: 11px 16px;
            width: 118px;
            height: 40px;
            border: 2px solid #F4F4F4;
            border-radius: 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            cursor: pointer;
            margin-top: -6px;
            margin-right: 16px;

            &-icon{
                width: 16px;
                height: 16px;
                margin-left: 9px;
                background: url(/img/warehouse-history-grey-icon.svg) center no-repeat;
                transition: .3s all;
            }

            &.active{
                color: #2A85FF;
                border-color: #2A85FF;
            }

            &.active &-icon{
                background: url(/img/warehouse-history-blue-icon.svg) center no-repeat;
            }
        }

        &-back{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #1A1D1F;
            cursor: pointer;
            margin-bottom: 14px;
    
            &-icon{
                width: 24px;
                height: 24px;
                cursor: pointer;
                // margin-right: 11px;
    
                &-container{
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-right: 11px;
                }
            }
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: calc(100% - 40px);
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-bottom: 8px;
        padding-right: 6px;

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding-top:15px;
            padding-right: 8px;
            border-radius: 0px;
            height: 50px;

            .workZone__clients-phone{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-point-name{
        width: calc(65% - 34px);
        margin-right: 5px; 
    }
    &-envelope-count{
        width: 35%;
        margin-right: 5px; 
    }
    &-open-icon{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}