.owner{
    &__lekals-purchase{
       background-color: #fff;
       border-radius: 8px;
       width: 100%; 
       margin-bottom: 10px;
       padding: 28px 24px 2px;

        &-title{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 40px;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #B1E5FC;
                border-radius: 4px;
            }

            @media (max-width: 1200px) {
                font-size: 22px;
            }

            @media (max-width: 500px) {
                font-size: 16px;
                margin-left: 20px;
                &::before{
                    left: -30px;
                }
            }
        }

        &-filters{
            position: relative;
            &-title{
                padding: 12px 16px;
                height: 48px;
                background: #FCFCFC;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                margin-left: 8px;
                cursor: pointer;

                &-icon{
                    display: block;
                    margin-left: 12px;
                    width: 15px;
                    height: 7px;
                }
            }
        }

        &-buttons{
            background: #F4F4F4;
            border-radius: 8px;
            width: 100%;
            height: 160px;
            padding: 8px;
            margin-top: 40px;
            transition: 1s all;
            cursor: pointer;

            &-purchase, &-income-summ{
                border-radius: 8px;
                width: 50%;
                height: 100%;
                // padding: 30px 12px 16px 35px;

                &.active{
                    background-color: #fff;
                }
            }

            &-icon{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 27px;
                margin-top: 2px;
                display: flex;
                & img{
                    width: 44px;
                    height: 44px;
                    display: block;
                }
                &.purchase{
                    background: #CABDFF;
                }
                &.income-summ{
                    background: #B1E5FC;
                }

                @media (max-width: 1200px) {
                    margin-right: 10px;
                }

                @media (max-width: 700px) {
                    display: none;
                }
            }

            &-title{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                padding-left: 5px;
                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }

            &-val{
                font-style: normal;
                font-weight: 600;
                font-size: 50px;
                line-height: 65px;
                text-align: center;
                color: #1A1D1F;

                @media (max-width: 1500px) {
                    font-size: 40px;
                }

                @media (max-width: 1250px) {
                    font-size: 34px;
                }

                @media (max-width: 1050px) {
                    font-size: 28px;
                    line-height: 45px;
                }

                @media (max-width: 600px) {
                    font-size: 24px;
                    line-height: 45px;
                }
            }

            &-val-percent{
                margin-top: auto;
                margin-left: auto;
                padding:  5px 6px;
                height: 24px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;                
                border-radius: 4px;
                width: 75px;

                @media (max-width: 1400px) {
                    margin-left: 0px;
                }

                &-icon{
                    margin-right: 2px;
                }

                &.green{
                    background: #83bf6e33;
                    color: #83BF6E;
                }

                &.red{
                    background: #FF6A551A;
                    color: #FF6A55;
                }
            }
        }

        &-graphik{
            width: 100%;
            height: 420px;
            border-radius: 8px;
            margin-top: 10px;
            position: relative;
        }
    }  
}