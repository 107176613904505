.salaries{
    &__table{
        background-color: #fff;
        border-radius: 8px;
        width: 100%; 
        max-height: 500px;
        min-height: 430px;
        height: 100%;
        margin-bottom: 20px;
        @media (max-width: 1000px) {
            height: auto;
        }

        &-filters{
            margin-bottom: 11px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #1A1D1F;

            &-item{
                padding: 11px 16px;
                border: 2px solid #e7e8e8;
                border-radius: 12px;
                margin-right: 16px;
                cursor: pointer;
                
                &-icon{
                    width: 16px;
                    height: 16px;

                    &.bottom{
                        margin-left: 9px;
                    }

                    &.right{
                        margin-left: 9px;
                        transform: rotate(-90deg);
                    }

                    &.left{
                        margin-right: 9px;
                        transform: rotate(90deg);
                    }
                }

                &.range{
                    margin-right: 0;

                    @media (max-width: 900px){
                        font-size: 14px;
                        padding: 11px 11px;
                    }

                    @media (max-width: 400px){
                        font-size: 12px;
                    }
                }

            }
        }

        &-data-block{
            height: 400px;
            overflow-x: hidden;
            overflow-y: hidden;
            padding-right: 15px;
            margin-right: 5px;

            @media (max-width: 900px){
                padding-right: 0;
                margin-right: 0;
            }
            

            &::-webkit-scrollbar{
                width: 10px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }
        }

        &-title-block{
            margin-right: 20px;
            @media (max-width: 900px){
                margin-right: 0px;
            }
        }


        &-staff{
            width: 150px;
            
            height: 100%;

            @media (max-width: 900px){
                width: 80px;
            }

            &.title{
                height: auto;
            }

            &-item{
                height: 75px;
                border-bottom: 1px solid #70767c80;
                border-right: 1px solid #70767c80;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;

                &.title{
                    color: #70767C;
                    height: 60px;
                }

                &-name{
                    font-weight: 600;
                    color: #1A1D1F;
                    margin-bottom: 6px;
                }

                &-position{
                    color: #70767C;
                }

                &.active{
                    border-bottom-color: #1379ff;
                }
            }
        }

        &-line.none{
            // overflow: hidden;
            height: 0;
        }

        &-scrollbar{
            width: calc(100% - 290px);
            margin-left: 150px;
            margin-top: 15px;
            overflow-y: hidden;
            overflow-x: scroll;
            @media (max-width: 900px){
                width: calc(100% - 180px);
                margin-left: 100px;
            }

            &::-webkit-scrollbar{
                width: 8px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 1px solid #E5E5E5;
            }

            &-width{
                height: 1px;
            }
        }

        &-tabel{
            width: calc(100% - 260px);
            padding: 0 2px 0 2px;
            height: 100%;
            position: relative;
            // overflow: hidden;

            &-grid{
                height: 100%;
                width: calc(100% - 4px);
                position: absolute;
                top: 0;
                z-index: 1;
                overflow: hidden;
            }

            @media (max-width: 900px){
                width: calc(100% - 180px);
            }

            &-line{
                &.active{
                    border-bottom-color: #1379ff;
                }
            }

            
        
            &.title{
                padding: 0;
                height: auto;
            }
            
            &-scroll{
                height: 100%;
                width: 100%;
                padding-bottom: 15px;
                overflow-x: scroll;
                overflow-y: hidden;
                position: absolute;
                z-index: 2;


                &::-webkit-scrollbar{
                    width: 8px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 1px solid #E5E5E5;
                }
            }

            &-title-scroll{
                height: 100%;
                // overflow-x: scroll;
                overflow: hidden;
                // overflow-y: scroll;

                &::-webkit-scrollbar{
                    width: 0;            
                }
            }

            &-item{
                min-width: 96px;
                width: 96px;
                height: 75px;
                background: #FCFCFC;
                border: 1px solid #F4F4F4;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #FCFCFC;
                cursor: pointer;

                &.title{
                    font-weight: 500;
                    color: #70767C;
                    cursor: default;
                    height: 60px;
                }

                &-container{
                    position: relative;
                    height: 30px;
                    width: 100%;
                    &.title{
                        height: auto;
                        width: auto;
                    }
                }
                
                &-block{
                    width: 78px;
                    height: 30px;
                    border-radius: 8px;
                    line-height: 30px;
                    text-align: center;

                    &.salary{
                        background: #2A85FF;
                        margin: auto;
                    }

                    &.premium{
                        background: #83BF6E;
                        margin: auto;
                    }

                    &.fine{
                        background: #FF6A55;
                        margin: auto;
                    }

                    &.prepayment{
                        background: #e8c43e;
                        margin: auto;
                    }

                    
                }
            }

            &-line.active &-item{
                border-bottom-color: #1379ff;
            }
        }

        &-total{
            width: 110px;
            height: 100%;

            @media (max-width: 900px){
                width: 100px;
            }

            &.title{
                height: auto;
            }

            &-item{
                height: 75px;
                border-bottom: 1px solid #70767c80;
                border-left: 1px solid #70767c80;
                border-right: 1px solid #70767c80;
                width: 100%;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;

                @media (max-width: 900px){
                    border-right: none;
                }

                &.title{
                    color: #70767C;
                    height: 60px;
                }

                &.active{
                    border-bottom-color: #1379ff;
                }
            }
        }
    }

    &__buttons{
        &-container{
            display: flex;
            @media (max-width: 450px) {
                display: block;
            }
        }
        background: #FCFCFC;
        border-radius: 8px;
        width: 100%;
        padding: 10px 24px 24px 24px;
        margin-top: 24px;
        margin-bottom: 8px;

        @media (max-width: 900px) {
            margin-bottom: 80px;
        }
        &-sale, &-income, &-purchase{
            width: calc(50% - 5px);
            height: 178px;
            padding: 40px 40px 32px 40px;
            border-radius: 8px;

            @media (max-width: 1400px) {
                height: 160px;
            }

            @media (max-width: 900px) {
                margin-bottom: 15px;
            }

            @media (max-width: 450px) {
                width: 100%;
                margin: auto;
                margin-bottom: 15px;
            }
        }

        &-sale{
            background: #e7f5ee;
        }

        &-income{
            background: #e6f6fd;
        }

        &-line{
            background-color: #F4F4F4;
            height: 160px;
            width: 3px;
            @media (max-width: 450px) {
                height: 3px;
                width: 100%;
            }
        }

        &-icon{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 27px;
            margin-top: 2px;
            display: flex;
            & img{
                width: 48px;
                height: 48px;
                display: block;
            }

            @media (max-width: 850px) {
                display: none;
            }

            @media (max-width: 450px) {
                display: flex;
            }
        }

        &-title{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;
            padding-left: 5px;
        }

        &-val{
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 65px;
            text-align: left;
            color: #1A1D1F;

            @media (max-width: 1400px) {
                font-size: 50px;
            }

            @media (max-width: 1200px) {
                font-size: 40px;
            }

            @media (max-width: 1050px) {
                font-size: 34px;
            }
        }

        &-val-percent{
            margin-top: 8px;
            margin-left: 0;
            padding:  5px 6px;
            height: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #70767C;              
            border-radius: 4px;
            width: auto;

            @media (max-width: 1400px) {
                margin-left: 0px;
                margin-top: 5px;
            }

            &-icon{
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
    }


}