.moderator__leaders{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 77vh;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 20px;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-add-leader-btn{
        width: 232px;
        height: 48px;
        font-size: 14px;
        color: #1C7FFF;
        padding: 20px 5px;
        cursor: pointer;

        &-icon{
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    &-table{
        height: calc(100% - 90px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: 100%;
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: calc(100%);
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        min-width: 720px;
        padding-right: 15px;
        
        & a{
            color: #1A1D1F;
        }
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding: 0 15px 20px 0;
            border-radius: 0px;
            height: 50px;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
        &.active{
            border-color: #1379ff;
        }
        &:hover{
            border-color: #85baff;
        }
    }

    &-person{
        width: 18%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        padding: 5px; /* Поля вокруг текста */
        text-overflow: ellipsis; /* Добавляем многоточие */
    }

    &-login{
        width: 17%;
    }

    &-password{
        width: 17%;
    }

    &-phone{
        width: 21%;
        margin-right: 5px;        
    }

    &-email{
        width: 25%;
        margin-right: 5px;  
    }

    &-open{
        display: block;
        width: 28px;
        height: 28px;
        cursor: pointer;

        &.none{
            opacity: 0;
            cursor: default;
        }
    }
}

.moderator-edit-icon-block{
    background-color: #6cacff;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    margin-right: 3px;
    cursor: pointer;

    & img{
        display: block;
        height: 17px;
    }
    &.none{
        opacity: 0;
        cursor: default;
    }
}

.delite-icon-block{
    background-color: #ff5e5e;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;

    &-center{
        width: 18px;
        position: relative;
    }

    &-line{
        width: 100%;
        height: 3px;
        background-color: #fff;
        border-radius: 2px;
        position: absolute;
        top: calc(50% - 1px);

        &.one{
            transform: rotate(45deg);
        }
        &.two{
            transform: rotate(-45deg);
        }
    }


    &.none{
        opacity: 0;
        cursor: default;
    }
}