.owner{

    &__right{
        &-title{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 60px;
            margin-bottom: 44px;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #FFBC99;
                border-radius: 4px;
            }

            @media (max-width: 1200px) {
                font-size: 22px;
                &::before{
                    left: -30px;
                }
            }

            @media (max-width: 600px) {
                font-size: 14px;
                margin-left: 65px;
                margin-bottom: 25px;
            }

        }

        &-top{
            margin-bottom: 16px;
            padding: 0px 38px;
            min-width: 420px;
            &-line{
                min-width: 342px;
                margin: 0 38px;
                height: 2px;
                background-color: #F4F4F4;
            }

            @media (max-width: 1200px) {
                min-width: 340px;
                padding: 0 38px 0 30px;
                &-line{
                    margin: 0 38px 0 30px;
                    min-width: 267px;
                }
            }

            @media (max-width: 550px) {
                min-width: 310px;
                padding: 0 20px 0 20px;
                &-line{
                    margin: 0 6px 0 20px;
                    min-width: 237px;
                }
            }
        }

        &-leader{
            width: 60%;
            margin-right: 5px;

            &.title{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
            }
        }
        &-list-leader{
            width: 60%;
            margin-right: 5px;
        }

        &-points{
            width: 10%;
            margin-right: 5px;
            text-align: center;
            &.title{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
            }
        }

        &-income{
            width: 20%;
            margin-right: 5px;
            text-align: right;
            &.title{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
            }
        }

        &-reset{
            height: 32px;
            background: #2a85ff1a;
            border-radius: 8px;
            padding: 6px 12px;
            color: #2A85FF;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            cursor: pointer;

            &:hover &-icon{
                transform: rotate(360deg);
            }

            &-icon{
                width: 20px;
                height: 20px;
                margin-right: 10px;
                transition: .5s all;
            }
        }

        &-list{
            overflow-y: scroll;
            height: calc(70vh - 145px);
            padding: 0px 4px 0px 20px;
            margin-top: 10px;
            min-width: 420px;

            @media (max-width: 1200px) {
                padding-left: 12px;
            }

            @media (max-width: 700px) {
                height: 420px;
                overflow-y: hidden;
            }

            &-scroll{
                margin-bottom: 20px;
                overflow-x: auto;
                overflow-y: hidden;
                padding-bottom: 20px;
                &::-webkit-scrollbar{
                    height: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 2px solid #E5E5E5;
                }
                
            }

            &::-webkit-scrollbar{
                width: 12px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }
            &-item{
                height: 96px;
                padding: 16px;
                border-radius: 8px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #1A1D1F;
                position: relative;
                cursor: pointer;
                margin-bottom: 5px;                

                &.active{
                    background: #F4F4F4;

                    &::before{
                        content: "";
                        position: absolute;
                        width: 64px;
                        height: 64px;
                        left: 16px;
                        top: 16px;
                        background: rgba(0, 0, 0, 0.5) url(/img/check-backgr.svg) no-repeat center;
                        z-index: 1;
                        border-radius: 50%;

                    }
                }
            }

            &-leader{
                &-photo{
                    width: 64px;
                    height: 64px;
                    border-radius: 32px;
                    margin-right: 24px;
                    position: relative;
                    overflow: hidden;

                    &-img{
                        height: 64px;
                        border-radius: 32px;
                        object-fit: cover;
                    }
                }

                &-name-container{
                    width: calc(100% - 90px);
                }

                &-city{
                    white-space: nowrap; /* Запрещаем перенос строк */
                    overflow: hidden; /* Обрезаем все, что не помещается в область */
                    text-overflow: ellipsis; /* Добавляем многоточие */
                    width: 100%;
                }

                &-points{
                    width: 66px;
                }

                &-income{
                    width: 66px;
                }
            }
            
        }
        &-income-val{
            margin-left: auto;
        }
        &-income-percent{
            margin-top: 7px;
            margin-left: auto;
            padding:  5px 6px;
            height: 24px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;                
            border-radius: 4px;

            &-icon{
                margin-right: 2px;
            }

            &.green{
                background: #83bf6e33;
                color: #83BF6E;
            }

            &.red{
                background: #FF6A551A;
                color: #FF6A55;
            }
        }
    }
         
}

@media (max-width: 1400px) {
    .owner{

        &__right{    
            &-income{
                &.title{
                    line-height: 14px;
                }
            }
    
            &-list{
                &-item{
                    font-size: 15px;
                }                
            }
        }
             
    }
}

@media (max-width: 1200px) {
    .owner{

        &__right{    
            &-leader{
                width: 60%;
                margin-right: 5px;
    
                &.title{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #70767C;
                }
            }
    
            &-points{
                width: 10%;
                margin-right: 5px;
                text-align: center;
                &.title{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #70767C;
                }
            }
    
            &-income{
                width: 20%;
                margin-right: 5px;
                text-align: right;
                &.title{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #70767C;
                }
            }
    
            &-list{
                min-width: 340px;
                &-item{
                    height: 96px;
                    padding: 16px;
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #1A1D1F;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 5px;
    
                    &.active{
                        background: #F4F4F4;
    
                        &::before{
                            content: "";
                            position: absolute;
                            width: 34px;
                            height: 34px;
                            left: 16px;
                            top: 30px;
                            background: rgba(0, 0, 0, 0.5) url(/img/check-backgr.svg) no-repeat center;
                            z-index: 1;
                            border-radius: 50%;
                            display: none;
    
                        }
                    }
                }
    
                &-leader{
                    &-photo{
                        display: none;
                    }

                    &-name-container{
                        width: 100%;
                    }
                }
                
            }
        }
             
    }
}

@media (max-width: 1000px) {
    .owner{
        &__right{

            padding: 0 18px 20px 10px;

            &-leader{    
                &.title{
                    font-size: 18px;
                }
            }
    
            &-points{
                &.title{
                    font-size: 18px;
                }
            }
    
            &-income{
                &.title{
                    font-size: 18px;
                }
            }
    
            &-list{
                &-item{
                    font-size: 18px;
                }
    
                &-leader{
                    &-photo{
                        display: block;
                    }

                    &-name-container{
                        width: calc(100% - 90px);
                    }
                }
                
            }
        }
             
    }
}

@media (max-width: 700px) {
    .owner{

        &__right{

            &-leader{    
                &.title{
                    width: 55%;
                    font-size: 12px;
                }
            }
    
            &-points{
                width: 18%;
                &.title{
                    width: 18%;
                    font-size: 12px;
                }
            }
    
            &-income{
                width: 22%;
                &.title{
                    font-size: 12px;
                }
            }
    
            &-list{

                &-leader{
                    width: 55%;
                    margin-right: 5px;
                }
                &-item{
                    height: 90px;
                    font-size: 14px;
                    padding: 16px 36px 16px 16px;
                    &.title{
                        font-size: 12px;
                    }
                }
    
                


                
            }
        }
             
    }
}

@media (max-width: 550px) {
    .owner{
        &__right{

            padding: 28px 4px;   
            &-list{
                min-width: 310px;
                padding: 0px 4px 0px 4px;
                &-leader-photo{
                    display: none;
                }

                &-leader-name-container{
                    width: calc(100% - 90px);
                }

                &-item{
                    padding: 14px;
                }
            }

            
                
        }
             
    }
}