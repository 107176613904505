.franchises-leaders{

    &__right{
        &-title{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 60px;
            margin-bottom: 44px;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #FFBC99;
                border-radius: 4px;
            }

            &.purpure::before{
                background: #CABDFF;
            }

            @media (max-width: 600px) {
                font-size: 18px;
                margin-left: 65px;
                margin-bottom: 25px;

                &::before{
                    left: -30px;
                }
            }
        }

        // &-top{
        //     margin-bottom: 16px;
        //     padding: 0px 38px;
        //     min-width: 272px;
        //     &-line{
        //         min-width: 224px;
        //         margin-left: 38px;
        //         height: 2px;
        //         background-color: #F4F4F4;
        //     }
        // }

        &-list{
            overflow-y: scroll;
            height: calc(70vh - 45px);
            // padding: 0px 4px 0px 20px;
            padding-right: 12px;
            padding-left: 20px;
            margin-top: 10px;
            min-width: 272px;

            @media (max-width: 700px) {
                height: 500px;
                overflow-y: hidden;
            }

            &::-webkit-scrollbar{
                width: 10px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }

            &-item{
                padding: 14px 11px;
                border-radius: 8px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;
                cursor: pointer;
                margin-bottom: 4px;
                margin-bottom: 4px;
                background: #FFFFFF;
                border-top: 1px solid #FFFFFF;
                transition: .3s all;

                box-shadow: 0px 8px 12px rgba(26, 29, 31, 0.05);
                border-radius: 8px;

                &.active{
                    box-shadow: 0px 0px 0px 1px #1379ff94;
                }
            }

            
        }

        &-points-name{
            width: calc(100% - 60px);

            &-icon{
                width: 20px;
                height: 20px;
            }
        }
    }
         
}

@media (max-width: 1400px) {
    .franchises-leaders{

        &__right{
    
            &-list{
                &-item{
                    font-size: 15px;
                }                
            }
        }
             
    }
}

@media (max-width: 1200px) {
    .franchises-leaders{

        &__right{
    
            &-list{
                &-item{
                    height: 96px;
                    padding: 16px;
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #1A1D1F;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 5px;
    
                    &.active{
                        background: #F4F4F4;
    
                        &::before{
                            content: "";
                            position: absolute;
                            width: 34px;
                            height: 34px;
                            left: 16px;
                            top: 30px;
                            background: rgba(0, 0, 0, 0.5) url(/img/check-backgr.svg) no-repeat center;
                            z-index: 1;
                            border-radius: 50%;
                            display: none;
    
                        }
                    }
                }
    
                &-leader{
                    &-photo{
                        display: none;
                        width: 34px;
                        height: 34px;
                        margin-right: 14px;
                        position: relative;
                    }
                }
                
            }
        }
             
    }
}

@media (max-width: 700px) {
    .franchises-leaders{

        &__right{
            &-list{
                &-item{
                    height: 90px;
                    font-size: 14px;
                    padding: 16px 36px 16px 16px;
                    &.title{
                        font-size: 12px;
                    }
                }
    
                


                
            }
        }
             
    }
}