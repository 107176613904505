.owner__leaders{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 77vh;

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-bottom: 20px;
        margin-left: 40px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }

        @media (max-width: 600px) {
            font-size: 18px;
            margin-left: 30px;

            &::before{
                left: -30px;
            }
        }

        @media (max-width: 320px) {
            font-size: 14px;
            margin-left: 25px;
        }
    }

    &-table{
        height: calc(100% - 60px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 770px;

        @media (max-width: 700px) {
            height: 340px;
            overflow-y: hidden;
        }

        &::-webkit-scrollbar{
            width: 12px;            
        }

        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }

        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }

        &-scroll{
            height: 93%;
            @media (max-width: 900px) {
                overflow-x: scroll;
                padding-bottom: 20px;

                &::-webkit-scrollbar{
                    width: 12px;            
                }

                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }

            @media (max-width: 700px) {
                overflow-x: scroll;
                // overflow-y: hidden;
                padding-bottom: 20px;
                margin-bottom: 20px;
                height: auto;

                &::-webkit-scrollbar{
                    width: 12px;            
                }

                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 60px;
        // border-radius: 8px;
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        cursor: pointer;
        min-width: 750px;
        color: #01040e;

        // @media (max-width: 600px) {
        //     font-size: 14px;
        // }
        
        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 22px);
            padding: 0 0 20px 0;
            border-radius: 0px;
        }

        &.active{
            border-color: #1379ff;
        }

        &:hover{
            border-color: #85baff;

        }
    }

    &-person{
        width: 24%;
        margin-right: 5px;

        &-photo{

            &-container{
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 16px;
                overflow: hidden;
            }

            height: 32px;
            width: auto;
            border-radius: 16px;
            display: block;
        }

    }

    &-amount-points{
        width: 19%;
        margin-right: 5px;
        text-align: center;            
    }

    &-income{
        width: 19%;
        margin-right: 5px;
        display: flex;
        text-align: center;                    
    }

    &-sales{
        width: 16%;
        margin-right: 5px;
        display: flex;
        text-align: center;                      
    }

    &-purchase{
        width: 16%;
        margin-right: 5px;
        display: flex;
        text-align: center;                      
    }

    &-open-icon{
        width: 40px;
        height: 28px;
        background: #F4F4F4;
        border-radius: 4px;
        padding: 2px 8px;
        & img{
            width: 24px;
            height: 24px;
        }

        &.none{
            background: none;
        }
    }

    &-val-percent{
        padding:  5px 6px;
        height: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;                
        border-radius: 4px;
        width: 60px;
    
        &-icon{
            margin-right: 2px;
        }
    
        &.green{
            color: #83BF6E;
        }
    
        &.red{
            color: #FF6A55;
        }
    }
         
}

@media (max-width: 1400px) {
    .owner__leaders{    
        &-income{
            width: 20%;
            margin-right: 5px;
            display: block;          
        }
    
        &-sales{
            width: 17%;
            margin-right: 5px;
            display: block;                      
        }
    
        &-purchase{
            width: 17%;
            margin-right: 5px;
            display: block;            
        }

        &-val-percent{
            margin: auto;

        }
             
    }
}

@media (max-width: 700px) {
    .owner__leaders{    
        height: auto;         
    }
}