.moderator__popup-delite{

    &-title{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;

        & span{
            border-bottom: 1px solid #000;
            display: inline-block;
        }
    }

    &-btn{
        width: 400px;
        margin: auto;

        &-item{
            width: 160px;
            padding: 10px;
            background-color: #2A85FF;
            color: #fff;
            border-radius: 8px;
            cursor: pointer;
            text-align: center;
        }
    }
}