.franchises-leaders__staf-points{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 75vh;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-top{
        margin-bottom: 20px;
    }

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        margin-left: 40px;
        margin-bottom: 0;

        &.leader{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #FFBC99;
            border-radius: 4px;
        }

        &.staf::before{
            background: #B1E5FC;
        }

        &.purpure::before{
            background: #CABDFF;
        }

        &.birch::before{
            background: #B5E4CA;
        }

        @media (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-back{
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #1A1D1F;
        cursor: pointer;
        margin-top: 40px;
        margin-bottom: 24px;
        width: 100px;

        & img{
            margin-right: 33px;
        }
    }

    &-table{
        height: calc(100% - 90px);
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 750px;

        @media (max-width: 1200px) {
            height: calc(100% - 40px);
        }

        @media (max-width: 700px) {
            min-height: 360px;
            height: 100%;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            width: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 4px solid #E5E5E5;
        }
        &-scroll{
            height: 100%;
            overflow-y: hidden;
            @media (max-width: 1200px) {
                margin-bottom: 20px;
                overflow-x: scroll;
                padding-bottom: 20px;
                height: calc(100% - 40px);
                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }
    }

    &-line{
        height: 72px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        transition: .3s all;
        
        @media (max-width: 700px) {
            font-size: 15px;
            height: 62px;
        }

        &-container{
            margin-top: 8px;
            width: calc(100% - 20px);
            border-bottom: 2px solid #f4f4f4;
            min-width: 730px;

            &.active{
                border-color: #1379ff;
            }
            &:hover{
                border-color: #85baff;
            }
        }

        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 40px);
            min-width: 730px;
            padding: 0 0 20px 0;
            border-radius: 0px;
            height: 50px;
            border-bottom: 2px solid #f4f4f4;

            .owner__leaders-staf-phone, .owner__leaders-staf-email{
                text-decoration: none;
                color: #70767C;
            }
        }
    }

    &-model{
        width: 20%;
        margin-right: 5px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        padding: 5px 0; /* Поля вокруг текста */
        text-overflow: ellipsis; /* Добавляем многоточие */
        transition: .3s all;        
    }

    &-code{
        width: 15%;
    }

    &-staff{
        width: 20%;
    }

    &-date{
        width: 20%;
    }

    &-price{
        width: 15%;
    }

    &-additionally-open{
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: .3s all;

        &.none{
            opacity: 0;
            cursor: default;
        }

        &.active{
            transform: rotate(180deg);
        }
    }

    &-additionally{
        height: 0;
        transition: .3s;
        overflow: hidden;
        padding-bottom: 0;

        &.active{
            height: 145px;
            margin-bottom: 30px;
        }

        &-item{
            &-label{
                color: #70767C;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 16px;

                &.model{
                    margin-bottom: 0;
                    margin-top: 20px;
                }
            }

            &-comment{
                background: #F4F4F4;
                border-radius: 4px;
                width: 100%;
                height: auto;
                padding: 14px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;

                &-scroll{
                    padding-right: 8px;
                    height: 110px;
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        width: 10px;            
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #E5E5E5;
                        border-radius: 10px;
                        
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #FCFCFC;
                        border-radius: 10px;
                        border: 2px solid #E5E5E5;
                    }
                }
            }
            &.comment{
                width: 50%;
                margin-right: 40px;
                
            }

            &.phone{
                width: 130px;
                margin-right: 64px;                               
            }

            &-phone{
                padding-top: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F; 
            }

            &.printing-type{
                width: 115px;
            }

            &-printing-type{
                padding-top: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;
            }

            &-model{
                padding-top: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #1A1D1F;
            }
        }

    }



}