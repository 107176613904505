.popup{
    &__back{
        width: 100%;
        height: 100%;
        background: #9c9c9c6c;
        backdrop-filter: blur(10px);
        opacity: 0;
        display: none;
        transition: .3s all;
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;

        &.display{
            display: block;
        }

        &.open{
            display: block;
            opacity: 1;
            overflow: hidden;
        }

        &-wrap{
            width: 100%;
            height: 100%;
        }
    }

    &__title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-left: 40px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }

        &.orange::before{
            background: #FFBC99;
        }

        &.green::before{
            background: #B5E4CA;
        }

        &.purpure::before{
            background: #CABDFF;
        }

        &.birch::before{
            background: #B5E4CA;
        }

    }

    &__close-btn{
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(../../../img/close-block-icon.svg);
        margin-top: 5px;
    }

    &__error{
        height: 15px;
        width: 100%;
        margin-top: 8px;
        color: #df3535;
        font-size: 12px;
    }
}

.moderator__popup{
    &-container{
        width: 498px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(26, 29, 31, 0.1);
        border-radius: 24px;
        padding: 24px;

        &.lekalo-edit{
            width: 428px;
        }

        &.delite{
            width: 520px;
            padding: 40px 10px;
        }

        &.add-event{
            width: 360px;
            padding: 24px 10px;
        }

        &-add-event-scroll{
            max-height: calc(85vh - 60px);
            overflow-y: scroll;
            padding: 0 10px;

            &::-webkit-scrollbar{
                width: 10px;            
            }
    
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
    
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }

        }
    }

    &-add-events-save-container{
        margin-top: auto;
        width: calc(50% - 10px);
    }

    &-add-events-line-container{
        height: 20px;
    }

    &-add-events-line{
        width: calc(100% - 38px);
        position: relative;
        height: 2px;
        background: #d8d8d8;
        border-radius: 1px;
        margin-left: 19px;
        margin-right: 19px;

        &.full-left{
            width: calc(100% - 19px);
            margin-left: 0;
        }
        &.full{
            width: 100%;
            margin-left: 0;
        }
    }

    &-add-events-btn{
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        top: -9px;
        left: -19px;
        cursor: pointer;
    }

    &-delite-events-btn{
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        top: -9px;
        right: -19px;
        cursor: pointer;
    }

    &-add-events-save{
        height: 40px;
        width: 100%;
        background: #2A85FF;
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #FCFCFC;
        cursor: pointer;

        &.deactive{
            cursor: default;
            pointer-events: none;
            background-color: #70767C;
        }
    }
    
    &-top{
        &.add-leaders{
            margin-bottom: 34px;
        }

        &.add-event{
            margin-bottom: 20px;
            padding: 0 20px 0 10px;
        }
    }

    &-label{
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        color: #70767C;
        margin-top: 16px;
        margin-bottom: 6px;
        display: block;

        &.mt-0{
            margin-top: 0;
        }
    }

    &-input{
        background: #F4F4F4;
        border-radius: 8px;
        padding: 12px;
        height: auto;
        width: 100%;
        font-size: 14px;
        &::placeholder{
            font-size: 12px;
            color: #b1b1b1;
        }

        &.size{
            width: 48%;
            padding: 0;
            padding-left: 12px;
        }

        &.zero{
            height: auto;
            display: inline-block;
            width: 90%;
            padding-left: 5px;
        }

        &-title{
            font-weight: 500;
            font-size: 14px;
            color: #1A1D1F;
        }

        &-fifty{
            width: calc(50% - 12px);
        }
    }

    &-lekalo-input{
        width: calc(100% - 32px);
        background: #F4F4F4;
        border-radius: 8px;
        padding: 12px;
        height: 38px;
        font-size: 14px;

        &-icon{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

    }

    &-description-input{
        &-container{
            background: #F4F4F4;
            border-radius: 8px;
            height: 156px;
            padding: 12px;
            width: 100%;
            font-size: 14px;
            &::placeholder{
                font-size: 12px;
                color: #b1b1b1;
            }
        }

        height: 132px;
        overflow-y: auto;
        padding-right: 12px;
        font-size: 14px;
        resize: none;
        width: 100%;

        &::-webkit-scrollbar{
            width: 10px;            
        }

        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }

        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 2px solid #E5E5E5;
        }
    }

    &-status-button{
        width: 160px;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        padding-left: 44px;
        position: relative;
        transition: .3s all;

        &::before{
            content: '';
            position: absolute;
            top: calc(50% - 12px);
            left: 12px;
            height: 24px;
            width: 24px;
        }

        &.green{
            background: #83BF6E;
            color: #fff;

            &::before{
                background: url(../../../img/moderator-popup-status-green.svg);
            }
        }

        &.grey{
            color: #70767C;
            background: #F4F4F4;

            &::before{
                background: url(../../../img/moderator-popup-status-grey.svg);
            }
        }
    }

    &-mailing{
        position: relative;   
        transition: 0.3s;                
        cursor: pointer;
        padding-left: 30px;
        color: #1A1D1F;
        height: 20px;
        font-weight: 500;
        margin-top: 18px;

        &::before{
            content: '';
            position: absolute;
            background: #F4F4F4;
            border-radius: 4px;
            width: 20px;
            height: 20px;
            left: 0px;
            transition: 0.3s;
            cursor: pointer;
        }

        &.active::before{
            background: url(/img/check.svg) center #2A85FF no-repeat;
            background-size: 70%;
            border-color: #2A85FF;
        }
    }

    &-save{
        width: 100%;
        padding: 11px;
        text-align: center;
        background: #1C7FFF;
        border-radius: 8px;
        cursor: pointer;
        transition: .3s all;
        color: #FCFCFC;
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;

        &.deactive{
            pointer-events: none;
            opacity: 0.6;
        }
    }

    &-date{
        width: 140px;

        &-container{
            background: #F4F4F4;
            border-radius: 8px;
            padding: 7px;
        }

        &-icon{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &-input{
            width: calc(100% - 32px);
            font-size: 14px;
            padding: 5px;
        }
    }

    &-point{
        width: calc(100% - 160px);

        &-select{
            width: 100%;
            position: relative;
            background: #F4F4F4;
            font-size: 14px;
            border-radius: 8px;
            padding: 12px;
            transition: .3s all;
            cursor: pointer;
            

            &.active{
                border-radius:8px 8px 0 0;
                background: #fff;
                box-shadow: 0px 2px 16px rgba(26, 29, 31, 0.16);
            }

            &::before{
                content: '';
                top: calc(50% - 12px);
                right: 16px;
            }

            &-container{
                position: relative;
            }

            &-list{
                width: 100%;
                max-height: 0px;
                position: absolute;
                border-radius: 0 0 8px 8px;
                top: 38px;
                background: #FFFFFF;
                transition: .3s all;
                padding: 0 14px 0;
                font-size: 14px;
                overflow: hidden;

                &.active{
                    padding: 5px 14px 10px;
                    max-height: 210px;
                    box-shadow: 0px 16px 16px rgba(26, 29, 31, 0.16);

                    &::before{
                        content: '';
                        width: 258px;
                        height: 2px;
                        background: #F4F4F4;
                        top: 0;
                        left: calc(50% - 129px);
                        position: absolute;
                    }
                }

                

                &-scroll{
                    overflow-y: auto;
                    max-height: 170px;

                    &::-webkit-scrollbar{
                        width: 10px;            
                    }
            
                    &::-webkit-scrollbar-track {
                        background-color: #E5E5E5;
                        border-radius: 10px;
                        
                    }
            
                    &::-webkit-scrollbar-thumb {
                        background-color: #FCFCFC;
                        border-radius: 10px;
                        border: 2px solid #E5E5E5;
                    }

                }

                &-item{
                    position: relative;   
                    transition: 0.3s;                
                    cursor: pointer;
                    padding: 12px;
                    padding-left: 30px;
                    color: #1A1D1F;
                    height: 38px;
                    
                   
                    &::before{
                        content: '';
                        position: absolute;
                        background: #F4F4F4;
                        border-radius: 4px;
                        width: 20px;
                        height: 20px;
                        left: 0px;
                        top: 10px;
                        transition: 0.3s;
                        cursor: pointer;
                    }

                    &.active::before{
                        background: url(/img/check.svg) center #2A85FF no-repeat;
                        background-size: 70%;
                        border-color: #2A85FF;
                    }
                }
            }
        }
    }
    
}