.moderator__history-changes{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    padding: 28px 24px;
    height: 77vh;

    &-title{
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        margin-bottom: 20px;
        margin-left: 40px;

        &::before{
            content: "";
            position: absolute;
            width: 16px;
            height: 40px;
            left: -40px;
            top: -6px;
            background: #B1E5FC;
            border-radius: 4px;
        }

        @media (max-width: 600px) {
            font-size: 18px;
            margin-left: 30px;

            &::before{
                left: -30px;
            }
        }

        @media (max-width: 320px) {
            font-size: 14px;
            margin-left: 25px;
        }
    }

    &-table{
        height: calc(100% - 115px);

        &-scrollX{
            height: 100%;
            @media (max-width: 900px) {
                overflow-x: scroll;
                padding-bottom: 20px;

                &::-webkit-scrollbar{
                    width: 12px;            
                }

                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }

            @media (max-width: 700px) {
                overflow-x: scroll;
                // overflow-y: hidden;
                padding-bottom: 20px;
                margin-bottom: 20px;
                height: auto;

                &::-webkit-scrollbar{
                    width: 12px;            
                }

                &::-webkit-scrollbar-track {
                    background-color: #E5E5E5;
                    border-radius: 10px;
                    
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #E5E5E5;
                }
            }
        }

        &-scroll{
            overflow-y: scroll;
            overflow-x: hidden;
            height: 100%;

            padding-right: 10px;
            min-width: 770px;
            
            @media (max-width: 700px) {
                height: 340px;
                overflow-y: hidden;
            }

            &::-webkit-scrollbar{
                width: 12px;            
            }

            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }

            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 4px solid #E5E5E5;
            }
        }
    }

    &-line{
        height: 70px;
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        width: 100%;
        border-bottom: 2px solid #f4f4f4;
        transition: .3s all;
        // cursor: pointer;
        min-width: 750px;

        @media (max-width: 600px) {
            font-size: 14px;
        }
        
        &.title{
            font-size: 14px;
            line-height: 17px;
            color: #70767C;            
            width: calc(100% - 20px);
            padding: 0 0 20px 0;
            border-radius: 0px;
        }

        &.active{
            border-color: #1379ff;
        }

        &:hover{
            border-color: #85baff;

        }
    }

    &-lekalo{
        width: 40%;
        margin-right: 5px;

        &-icon{
            width: 42px;
            height: 42px;
            margin-right: 8px;
            display: block;
        }

    }

    &-status{
        width: 20%;
        margin-right: 5px;
        display: flex;
        text-align: center;
        
        &-point{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 8px;

            &.green{
                background: #83BF6E;
            }

            &.red{
                background: #FF6A55;
            }

            &.blue{
                background: #1C7FFF;
            }
            &.grey{
                background: #e4e4e4;
            }
        }

        &-title{
            width: calc(100% - 12px);
            text-align: left;

            &.grey{
                font-size: 12px;
                color: #70767C;
                line-height: 12px;
                font-weight: 500;
            }
        }
    }

    &-date{
        width: 30%;
        margin-right: 5px;
        text-align: center;            
    }

    &-refresh-icon{
        width: 24px;
        height: 24px;
        border-radius: 2px;
        display: block;
        cursor: pointer;

        &.none{
            opacity: 0;
            cursor: default;
            pointer-events: none;
        }

        &.opacity{
            opacity: 0.2;
            cursor: default;
            pointer-events: none;
        }
    }
         
}

@media (max-width: 1400px) {
    .owner__leaders{    
        &-lekalo{
            width: 20%;
            margin-right: 5px;
            display: block;          
        }
    
        &-status{
            width: 17%;
            margin-right: 5px;
            display: block;                      
        }
    
        &-date{
            width: 17%;
            margin-right: 5px;
            display: block;            
        }
             
    }
}