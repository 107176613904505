.owner{
    &__warehouse-right{
        background-color: #fff;
        border-radius: 8px;
        // width: 36%;
        min-width: 440px;
        padding: 28px 20px;
        height: 78vh;

        @media (max-width: 1200px) {
            min-width: 380px;
        }

        @media (max-width: 999px) {
            margin-top: 15px;
            width: 100%;
            min-width: auto;
        }

        @media (max-width: 700px) {
           height: auto;
        }
    }
    &__lekals-top{
        &-scroll{
            height: calc(100% - 55px);
            // overflow-x: scroll;
            // overflow-y: hidden;
            // &::-webkit-scrollbar{
            //     height: 10px;            
            // }
            // &::-webkit-scrollbar-track {
            //     background-color: #E5E5E5;
            //     border-radius: 10px;
                
            // }
            // &::-webkit-scrollbar-thumb {
            //     background-color: #FCFCFC;
            //     border-radius: 10px;
            //     border: 2px solid #E5E5E5;
            // }
            
            // @media (max-width: 500px) {
            //     margin-bottom: 20px;
            //     padding-bottom: 20px;
            // }
        }

        &-title{
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            margin-left: 37px;
            margin-bottom: 30px;

            &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 40px;
                left: -40px;
                top: -6px;
                background: #FFBC99;
                border-radius: 4px;
            }

            @media (max-width: 1200px) {
                font-size: 22px;
            }
        }
        &-filters{
            margin-top: -6px;
            position: relative;
            &-title{
                padding: 12px 16px;
                height: 48px;
                background: #FCFCFC;
                border: 2px solid #F4F4F4;
                border-radius: 12px;
                margin-left: 8px;
                cursor: pointer;

                &-icon{
                    display: block;
                    margin-left: 12px;
                    width: 15px;
                    height: 7px;
                }
            }
        }

        &-line{
            padding: 20px 0;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #1A1D1F;
            min-width: 400px;
            padding-right: 10px;

            &.title{
                border-bottom: 2px solid #F4F4F4;
                padding-bottom: 29px;
                padding-top: 0;
                height: auto;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #70767C;
                border-radius: 0;
                padding-right: 10px;
            }

            @media (max-width: 1200px) {
                min-width: 340px;
            }

            @media (max-width: 700px) {
                font-size: 16px;
            }
        }

        &-number{
            width: 8%;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: #70767C;
            margin-right: 5px;
        }

        &-name{
            width: 38%;
            margin-right: 5px;
        }

        &-code{
            width: 27%;
            margin-right: 5px;
            text-align: center;
        }

        &-sales{
            width: 27%;
            text-align: center;
        }

        &-list{
            overflow-y: scroll;
            overflow-x: hidden;
            height: calc(100% - 60px);
            margin-top: 10px;
            min-width: 400px;
            &::-webkit-scrollbar{
                width: 10px;            
            }
            &::-webkit-scrollbar-track {
                background-color: #E5E5E5;
                border-radius: 10px;
                
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FCFCFC;
                border-radius: 10px;
                border: 2px solid #E5E5E5;
            }

            @media (max-width: 1200px) {
                min-width: 340px;
            }

            @media (max-width: 700px) {
                overflow-y: hidden;
                min-height: 210px;
            }
            
        }
    }
         
}