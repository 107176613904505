.franchises-leaders__salaries{
    background-color: #fff;
    border-radius: 8px;
    width: 100%; 
    max-height: 825px;
    min-height: 600px;
    height: 100%;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        height: auto;
    }

    &-filters{
        margin-bottom: 11px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1A1D1F;

        &-item{
            padding: 11px 16px;
            border: 2px solid #e7e8e8;
            border-radius: 12px;
            margin-right: 16px;
            cursor: pointer;
            
            &-icon{
                width: 16px;
                height: 16px;

                &.bottom{
                    margin-left: 9px;
                }

                &.right{
                    margin-left: 9px;
                    transform: rotate(-90deg);
                }

                &.left{
                    margin-right: 9px;
                    transform: rotate(90deg);
                }
            }
        }

        &-block-item-title{
            border: 2px solid rgba(112, 118, 124, 0.1);
            border-radius: 12px;
            height: 100%;
            background: none;
        }
    }

    &-data-block{
        height: 490px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 15px;
        margin-right: 5px;

        &::-webkit-scrollbar{
            width: 10px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 2px solid #E5E5E5;
        }
    }

    &-title-block{
        margin-right: 30px;
    }


    &-staff{
        width: 150px;
        
        height: 100%;

        &.title{
            height: auto;
        }

        &-item{
            height: 75px;
            border-bottom: 1px solid #70767c80;
            border-right: 1px solid #70767c80;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            &.title{
                color: #70767C;
                height: 60px;
            }

            &-name{
                font-weight: 600;
                color: #1A1D1F;
                text-align: center;
                margin-bottom: 6px;
            }

            &-position{
                color: #70767C;
                text-align: center;
            }

            &.active{
                border-bottom-color: #1379ff;
            }
        }
    }

    &-line.none{
        // overflow: hidden;
        height: 0;
    }

    &-scrollbar{
        // width: calc(100% - 290px);
        width: calc(100% - 30px);
        margin: 0 15px;
        height: 20px;
        // margin-left: 150px;
        margin-top: 15px;
        overflow-y: hidden;
        overflow-x: scroll;

        &::-webkit-scrollbar{
            width: 8px;
            height: 12px;            
        }
        &::-webkit-scrollbar-track {
            background-color: #E5E5E5;
            border-radius: 10px;
            
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FCFCFC;
            border-radius: 10px;
            border: 1px solid #E5E5E5;
        }

        &-width{
            height: 1px;
        }
    }

    &-tabel{
        width: calc(100% - 260px);
        padding: 0 2px 0 2px;
        height: 100%;

        &-line{
            &.active{
                border-bottom-color: #1379ff;
            }
        }
       
        &.title{
            padding: 0 2px;
            height: auto;
        }
        
        &-scroll{
            height: 100%;
            padding-bottom: 15px;
            // overflow-x: scroll;
            overflow: hidden;
            // overflow-y: scroll;


            &::-webkit-scrollbar{
                width: 0px;            
            }
        }

        &-title-scroll{
            height: 100%;
            // overflow-x: scroll;
            overflow: hidden;
            // overflow-y: scroll;

            &::-webkit-scrollbar{
                width: 0;            
            }
        }

        &-item{
            min-width: 96px;
            width: 96px;
            height: 75px;
            background: #FCFCFC;
            border: 1px solid #F4F4F4;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #FCFCFC;
            cursor: pointer;

            &.title{
                font-weight: 500;
                color: #70767C;
                cursor: default;
                height: 60px;
            }

            &-container{
                position: relative;
                height: 30px;
                width: 100%;
                &.title{
                    height: auto;
                    width: auto;
                }
            }
            
            &-block{
                width: 78px;
                height: 30px;
                border-radius: 8px;
                line-height: 30px;
                text-align: center;

                &.salary{
                    background: #2A85FF;
                    position: absolute;
                    left: 2px;
                    z-index: 3;
                }

                &.premium{
                    background: #83BF6E;
                    position: absolute;
                    left: 6px;
                    z-index: 2;
                }

                &.fine{
                    background: #FF6A55;
                    position: absolute;
                    left: 10px;
                    z-index: 1;
                }

                &.prepayment{
                    background: #e8c43e;
                    position: absolute;
                    left: 14px;
                    z-index: 0;
                }

                
            }
        }

        &-line.active &-item{
            border-bottom-color: #1379ff;
        }
    }

    &-total{
        width: 110px;
        height: 100%;

        &.title{
            height: auto;
        }

        &-item{
            height: 75px;
            border-bottom: 1px solid #70767c80;
            border-left: 1px solid #70767c80;
            border-right: 1px solid #70767c80;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #1A1D1F;

            &.title{
                color: #70767C;
                height: 60px;
            }

            &.active{
                border-bottom-color: #1379ff;
            }
        }
    }


}