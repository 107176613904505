.work-zone{
    &__sidebar{
        position: fixed;
        width: 384px;
        min-height: 100vh;
        height: 100%;
        left: 0px;
        top: 0px;
        padding: 28px 0px 40px 20px;
        z-index: 1;

        &.sidebar-none{
            position: absolute;
        }

        // @media (max-width: 1600px) {
        //     width: 300px;
        //     padding-right: 10px;
        // }

        // @media (max-width: 700px) {
        //     display: none;
        // }

        &-catalog{
            background: #FCFCFC;
            margin-bottom: 40px;
            padding:26px;
            padding-right: 15px;
            border-radius: 12px;
            margin-top: 50px;
            height: calc(100% - 90px);
            // transition: .3s all;
            overflow: hidden;

            &.sidebar-none{
                width: 0;
                padding: 26px 0;
            }


            &-top{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #1A1D1F;
                margin-bottom: 28px;

                &-icon{
                    background: url(/img/arrow-left-blue.svg);
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    margin-right: 11px;
                }


            }

            &-path{
                margin-bottom: 20px;
                &-item{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #90959D;
                    margin-right: 4px;
                    cursor: pointer;
                    max-width: 100px;
                    white-space: nowrap; /* Запрещаем перенос строк */
                    overflow: hidden; /* Обрезаем все, что не помещается в область */
                    text-overflow: ellipsis; /* Добавляем многоточие */

                    & span{
                        margin-right: 4px;
                    }
                }

                &-button{
                    width: 0px;
                    height: 20px;
                    border-radius: 4px;
                    transition: .3s all;
                    overflow: hidden;
                    cursor: pointer;
                    
                    &.active{
                        width: 20px;
                    }

                    &-icon{
                        width: 20px;
                        height: 20px;
                    }

                    &-container{
                        position: relative;
                        height: 20px;
                        margin-right: 6px;
                        transition: .3s all;
                    }

                    &-list-block{
                        position: absolute;
                        top: 30px;
                        left: 0px;
                        width: 270px;
                        max-height: 0px;
                        padding: 0 14px;
                        border-radius: 8px;
                        background: #FCFCFC;
                        transition: .3s all;
                        overflow: hidden;

                        &.active{
                            max-height: 350px;
                            padding: 14px;
                            border: 1px solid #dddddd;
                            box-shadow: 0px 1px 24px rgb(48 52 54 / 16%);
                        }


                        &-scroll{
                            padding-right: 10px;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            height: 100%;

                            &::-webkit-scrollbar{
                                width: 8px;            
                            }
                            &::-webkit-scrollbar-track {
                                background-color: #f4f4f4;
                                border-radius: 10px;
                                
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: #fcfcfc;
                                border-radius: 10px;
                                border: 4px solid #f4f4f4;
                            }
                        }


                        &-item{
                            height: 30px;
                            width: 100%;
                            cursor: pointer;
                            margin-bottom: 10px;

                            &-icon{
                                width: 30px;
                                height: 30px;
                            }

                            &-name{
                                margin-left: 10px;
                                width: calc(100% - 50px);
                                white-space: nowrap; /* Запрещаем перенос строк */
                                overflow: hidden; /* Обрезаем все, что не помещается в область */
                                text-overflow: ellipsis; /* Добавляем многоточие */
                            }
                        }
                    }
                }
            }

            &-container{
                padding-right: 10px;
                overflow-y: scroll;
                overflow-x: hidden;
                height: calc(100% - 100px);

                &::-webkit-scrollbar{
                    width: 12px;            
                }
                &::-webkit-scrollbar-track {
                    background-color: #f4f4f4;
                    border-radius: 10px;
                    
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #FCFCFC;
                    border-radius: 10px;
                    border: 4px solid #f4f4f4;
                }
            }

            &-item{
                height: 70px;
                width: 100%;
                cursor: pointer;

                &-icon{
                    width: 32px;
                    height: 32px;
                }

                &-name{
                    margin-top: 10px;
                    margin-left: 10px;
                    color: #90959D;
                    font-weight: 600;
                    transition: .3s all;

                    &.active, &.folder{
                        color: #1A1D1F;
                    }
                }

                &.active{
                    border-bottom: 1px solid #1379ff;
                }                
            }
        }
    }

    &__logo{
        width: 35px;
        height: 48px;
        // margin: 29px 0 40px 28px;
        // @media (max-width: 1600px) {
        //     margin: 29px 0px 0px 28px;
        // }
    }
    &__top-buttons{
        &-item{
            position: relative;
        }
        &-icon{
            width: 40px;
            height: 40px;
            border-radius: 20px;
            transition: .3s all;
            cursor: pointer;

            &.workspase{
                background: url(/img/work-zone-workspase-icon.svg) center #FFF no-repeat;
                background-size: 24px;

                &.active{
                    background: url(/img/work-zone-workspase-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-workspase-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-workspase-hover-icon.svg) center #FFF no-repeat;
                }
            }
            &.clients{
                background: url(/img/work-zone-clients-icon.svg) center #FFF no-repeat;
                background-size: 22px;

                &.active{
                    background: url(/img/work-zone-clients-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-clients-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-clients-hover-icon.svg) center #FFF no-repeat;
                    
                }
            }
            &.history{
                background: url(/img/work-zone-history-icon.svg) center #FFF no-repeat;
                background-size: 24px;

                &.active{
                    background: url(/img/work-zone-history-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-history-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-history-hover-icon.svg) center #FFF no-repeat;
                }
            }
            &.suport{
                background: url(/img/work-zone-support-icon.svg) center #FFF no-repeat;
                background-size: 24px;

                &.active{
                    background: url(/img/work-zone-support-active-icon.svg) center #2A85FF no-repeat;

                    &:hover{
                        background: url(/img/work-zone-support-active-icon.svg) center #2A85FF no-repeat;
                    }
                }

                &:hover{
                    background: url(/img/work-zone-support-hover-icon.svg) center #FFF no-repeat;
                }
            }
        }

        &-popup{
            position: absolute;
            top: 56px;
            right: -180px;
            z-index: 2;
            background: #FCFCFC;
            width: 400px;
            max-height: 420px;
            border: 0.5px solid #F4F4F4;
            box-shadow: 0px 10px 24px rgba(26, 29, 31, 0.08);
            border-radius: 12px;
            padding: 20px 12px;
            transition: .5s all;
            transform: translateY(-700px);
            opacity: 0;

            &.active{
                transform: translateY(0px);
                opacity: 1;
            }

            &-title{
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 500;
                padding-left: 8px;
            }

            &-btn{
                width: 100%;
                padding: 11px;
                text-align: center;
                background: #1C7FFF;
                border-radius: 8px;
                cursor: pointer;
                transition: .3s all;
                color: #FCFCFC;
                font-size: 14px;
                font-weight: 500;
                margin-top: 16px;

                &.deactive{
                    background: #90959D;
                    pointer-events: none;
                }
            }
        }

    }
         
}