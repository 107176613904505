.franchises-leaders{
    font-family: "Inter", sans-serif;
    padding-left: 384px;
    color: #1A1D1F;;
    position: relative;
    // min-height: 100vh;
    // height: 100%;
    
    
    @media (max-width: 1600px) {
        padding-left: 300px;
    }    

    &__wrap{
        padding: 24px 40px 0px;
        width: 100%;
    }

    &__title{
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #1A1D1F;
        margin:48px 0 24px;
        @media (max-width: 1600px){
            font-size: 24px;
            line-height: 24px;
            margin: 10px 0 10px;
        }
    }

    &__content{
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &-left{
            width: 67%;
            max-height: 77vh; 
            border-radius: 8px;
            margin-right: 10px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0;
            }

            &-container{
                width: 100%;
            }
        }

        @media (max-width: 999px) {
            display: block;
        }
    }

    &__content-right{
        background-color: #fff;
        border-radius: 8px;
        min-width: 400px;
        width: 32%;
        padding: 28px 10px;
        height: 77vh;

        @media (max-width: 999px) {
            width: 100%;
            margin-top: 20px;
            min-width: auto;
        }
    }         
}

.lekals-purchase__graf{
    width: 100%;
    height: 95%;
    margin-top: 3%;
    margin-left: -2.5%;
}

.sales-income__graf{
    width: 100%;
    height: 90%;
    margin-top: 3%;
    margin-left: -2.5%;
}

.back-popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

// .graf-loading{
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background-color: #5b626b8f;
//     backdrop-filter: blur(10px);
//     display: none;
//     opacity: 0;
//     top: 0;
//     left: 0;
//     transition: .3s all;

//     &.active{
//         display: flex;
//         opacity: 1;
//     }
    
//     &__icon{
//         width: 60px;
//         height: 60px;
//         border-radius: 50%;
//         border:10px solid #9AACB8;
//         border-right-color: #2A85FF;
//         -webkit-animation: spin 2s linear infinite; /* Safari */
//         animation: spin 2s linear infinite;

//         @-webkit-keyframes spin {
//             0% { -webkit-transform: rotate(0deg); }
//             100% { -webkit-transform: rotate(360deg); }
//           }
          
//         @keyframes spin {
//         0% { transform: rotate(0deg); }
//         100% { transform: rotate(360deg); }
//         }
//     }
// }

@media (max-width: 1400px) {
    .franchises-leaders{
        padding-left: 104px;  

        &__wrap{
            padding: 24px 40px 0px;
            width: 100%;
        }
        
        &__content{
            &-left{
                max-height: 78vh;
            }
        }
    
        &__content-right{
            height: 78vh; 
        }  
    }
}

@media (max-width: 999px) {
    .franchises-leaders{
        // padding-left: 0;  

        &__wrap{
            padding: 14px 20px 0px;
            width: 100%;
        }
        
        &__content{
            &-left{
                width: 100%;
                height: auto;
                max-height: none;
                margin-right: 0;
            }
        }
    
        &__content-right{
            height: 686px; 
        }  
    }
}

@media (max-width: 700px) {
    .franchises-leaders{
        padding-left: 0;

        &__content-right{
            height: auto; 
        }
    }
}

textarea{
    resize: none;
    &::-webkit-scrollbar{
        width: 0px;            
    }
}